import { TagArea, TextInput } from "components/core";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { wrapClick } from "utils";
import { MetersInfoFormSchema, IMeterShipmentFormSchema } from "./schema";
import { MeterBrandPicker, MeterModelPicker } from "containers";
import { gql, useLazyQuery } from "@apollo/client";

interface MetersInfoFormProps {
  handleNext: (values: IMeterShipmentFormSchema["metersInfo"]) => void;
  handlePrevious: () => void;
  initialValues: IMeterShipmentFormSchema["metersInfo"];
  values: IMeterShipmentFormSchema;
  handleCancel: () => void;
}

const GET_CODE = gql`
  query GetMeterShipmentCode {
    code: getMeterShipmentCode
  }
`;

const MetersInfoForm: FC<MetersInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const [getCode] = useLazyQuery(GET_CODE, {
    fetchPolicy: "network-only"
  });
  const form = useFormik<IMeterShipmentFormSchema["metersInfo"]>({
    initialValues,
    validationSchema: MetersInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  useEffect(() => {
    if (!form.values.code) {
      getCode().then(({ data }) => {
        if (data?.code) {
          form.setFieldValue("code", data?.code);
        }
      });
    }
  }, [form, form.values.code, getCode]);

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Shipment Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="">
              <TextInput
                id="code"
                label="Shipment Code"
                type="text"
                placeholder="e.g. Wasion"
                disabled
                {...form}
              />
            </div>

            <div className="col-span-2">
              <TextInput
                id="description"
                label="Shipment Description"
                type="text"
                placeholder="e.g. Wasion"
                {...form}
              />
            </div>
          </div>
        </div>

        <div className="pt-6">
          <span className="text-xs font-light">Meters Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="">
              <MeterBrandPicker
                id="brand"
                label="Meter Brand"
                placeholder="e.g. Huawei"
                required={true}
                {...form}
              />
            </div>

            {form.values.brand && (
              <div className="">
                <MeterModelPicker
                  id="model"
                  label="Meter Model"
                  placeholder="eg. Wasion"
                  required={true}
                  filter={{ brand: form.values.brand?._id }}
                  {...form}
                />
              </div>
            )}

            {/* <div className="col-start-1">
              <TextInput
                id="startSerialNumber"
                label="Start Serial Number"
                type="text"
                placeholder="e.g. Huawei"
                {...form}
              />
            </div>

            <div className="">
              <TextInput
                id="quantity"
                label="Quantity"
                type="number"
                placeholder="e.g. Wasion"
                {...form}
              />
            </div>

            <div className="">
              <TextInput
                id="endSerialNumber"
                label="End Serial Number"
                type="text"
                placeholder="e.g. Wasion"
                {...form}
                disabled
              />
            </div> */}
            <div className="col-start-1 col-span-3">
              <TagArea
                id="serialNumbers"
                label="Serial Numbers"
                placeholder="e.g. P13013093939"
                {...form}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default MetersInfoForm;
