import { withPermissions, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { MaterialRequestView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_MATERIAL_REQUEST } from "apollo/queries";

export default function ViewMaterialRequestContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_MATERIAL_REQUEST, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const dispatchAction = (action: "cancel") => () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: action,
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Material Request Information"
      description="Details of material request are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "material-outgoing-requests:*", "material-outgoing-requests:cancel"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(dispatchAction("cancel"))}
              >
              Cancel
            </button>
          )}
        </>
      )}
    >
      {data?.materialRequest && (
        <MaterialRequestView materialRequest={data?.materialRequest} />
      )}
    </Modal>
  );
}
