import { gql, useQuery } from '@apollo/client';
import { MaterialShipmentView, Modal } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_MATERIAL_SHIPMENT = gql`
  query GetMaterialShipment($id: ID!) {
    materialShipment: getMaterialShipment(id: $id) {
      _id
      code
      description
      items {
        material {
          _id
          code
          name
          quantityUnit
        }
        quantity
      }
      createdBy {
        _id
        code
        emailAddress
        firstName
        gender
        ghanaCardNumber
        lastName
        phoneNumber
        profileImageUrl
      }
      createdAt
      updatedAt
    }
  }
`

export default function ViewMaterialShipmentContainer({ open, setOpen }: { open: boolean; setOpen: (val: boolean) => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_MATERIAL_SHIPMENT, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Material Shipment Information"
      description="Details of material shipment are shown below"
    >
      <MaterialShipmentView
        materialShipment={data?.materialShipment}
      />
    </Modal>
  )
}