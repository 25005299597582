export default function convertBackendEnumToString(str: string){
    let spacifiedString = '';
    for(let i=0; i < str.length; i++){ // old-school
      let charCode = str.charCodeAt(i) 
      if(charCode > 64 && charCode <= 90 && i != 0){
        spacifiedString += " ";
      }
      spacifiedString += str[i]
    }
    return spacifiedString;
  }
