import { useQuery, useReactiveVar } from "@apollo/client";
import { Shimmers, TableComponent } from "components";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, classNames, useTableData } from "utils";
import config from "config";
import ApproveMaterialRequestContainer from "./approve";
import RejectMaterialRequestContainer from "./reject";
import ViewMaterialRequestContainer from "./view";
import { Avatar, SelectInput } from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import { ExportDataContainer } from "containers";
import OfficeHeader from "components/layouts/office-header";
import numeral from "numeral";
import {
  GET_MATERIAL_REQUESTS,
  GET_MATERIAL_REQUEST_SUMMARY,
} from "apollo/queries";

const orderStatuses = [
  { name: "Pending", href: "Pending" },
  { name: "Approved", href: "Approved" },
  { name: "Rejected", href: "Rejected" },
];

const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 5,
  },
];
const MaterialRequestsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [orderStatus] = useUrlState("orderStatus");

  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: searchParams.orderStatus || undefined,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_MATERIAL_REQUESTS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
      onCompleted: () => {
        refetchSummary?.();
      },
    }
  );

  const { data: dataSummary, refetch: refetchSummary } = useQuery(
    GET_MATERIAL_REQUEST_SUMMARY,
    {
      variables: {
        region: searchParams.region || undefined,
        district: searchParams.district || undefined,
      },
      notifyOnNetworkStatusChange: false,
    }
  );
  useEffect(() => {
    refetch();
  }, [searchParams.orderStatus]);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <OfficeHeader />
      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            <Link
              search={(old) => ({
                ...old,
                orderStatus: undefined,
              })}
              className={classNames(
                !orderStatus
                  ? "border-primary-500 text-primary-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!orderStatus ? "page" : undefined}
            >
              All Requisitions
              <span
                className={classNames(
                  !orderStatus
                    ? "bg-primary-100 text-primary-600"
                    : "bg-gray-100 text-gray-900",
                  "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                )}
              >
                {_.sum(
                  _.values(
                    _.omit(dataSummary?.getMaterialRequestSummary, "__typename")
                  )
                )}
              </span>
            </Link>
            {orderStatuses.map((_orderStatus) => (
              <Link
                key={_orderStatus.name}
                search={(old) => {
                  return {
                    ...old,
                    orderStatus: _orderStatus.href,
                  };
                }}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
                {_orderStatus.href ? (
                  <span
                    className={classNames(
                      orderStatus === _orderStatus.href
                        ? "bg-primary-100 text-primary-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {dataSummary?.getMaterialRequestSummary?.[
                      _orderStatus?.href
                    ] || 0}
                  </span>
                ) : null}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"material requisitions"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            renderHeaderItems={() => (
              <div className='mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2'></div>
            )}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="MaterialRequest"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Date
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Items
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Created By
                </th>
                {["Approved"].includes(orderStatus) && (
                  <>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Approved By
                    </th>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Approved At
                    </th>
                  </>
                )}
                {["Rejected"].includes(orderStatus) && (
                  <>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Rejected By
                    </th>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Rejected At
                    </th>
                  </>
                )}
                {!orderStatus && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Status
                  </th>
                )}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.AvatarShimmer />
                </td>
                {["Approved"].includes(orderStatus) && (
                  <>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.AvatarShimmer />
                    </td>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                  </>
                )}
                {["Rejected"].includes(orderStatus) && (
                  <>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.AvatarShimmer />
                    </td>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                  </>
                )}
                {!orderStatus && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='font-medium text-gray-500 dark:text-gray-400'>
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {numeral(item?.items.length).format("0,0") || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.createdBy?.lastName || "")?.trim(),
                            (item?.createdBy?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.createdBy?.profileImageUrl || ""}
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {[
                          (item?.createdBy?.lastName || "")?.trim(),
                          (item?.createdBy?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.createdBy?.phoneNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                {["Approved"].includes(orderStatus) && (
                  <>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      <div className='flex items-center'>
                        <div className='h-10 w-10 flex-shrink-0'>
                          <Avatar
                            disabled={true}
                            alt={
                              [
                                (item?.approvedBy?.lastName || "")?.trim(),
                                (item?.approvedBy?.firstName || "")?.trim(),
                              ]
                                .join(" ")
                                .trim() || "N A"
                            }
                            src={item?.approvedBy?.profileImageUrl || ""}
                          />
                        </div>
                        <div className='ml-4'>
                          <div className='text-gray-900 dark:text-gray-100'>
                            {[
                              (item?.approvedBy?.lastName || "")?.trim(),
                              (item?.approvedBy?.firstName || "")?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"}
                          </div>
                          <div className='text-gray-500 dark:text-gray-400'>
                            {item?.approvedBy?.phoneNumber || "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                      <div className='font-medium text-gray-500 dark:text-gray-400'>
                        {moment(item?.approvedAt).format(dateFormat)}
                      </div>
                    </td>
                  </>
                )}
                {["Rejected"].includes(orderStatus) && (
                  <>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      <div className='flex items-center'>
                        <div className='h-10 w-10 flex-shrink-0'>
                          <Avatar
                            disabled={true}
                            alt={
                              [
                                (item?.rejectedBy?.lastName || "")?.trim(),
                                (item?.rejectedBy?.firstName || "")?.trim(),
                              ]
                                .join(" ")
                                .trim() || "N A"
                            }
                            src={item?.rejectedBy?.profileImageUrl || ""}
                          />
                        </div>
                        <div className='ml-4'>
                          <div className='text-gray-900 dark:text-gray-100'>
                            {[
                              (item?.rejectedBy?.lastName || "")?.trim(),
                              (item?.rejectedBy?.firstName || "")?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"}
                          </div>
                          <div className='text-gray-500 dark:text-gray-400'>
                            {item?.rejectedBy?.phoneNumber || "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                      <div className='font-medium text-gray-500 dark:text-gray-400'>
                        {moment(item?.rejectedAt).format(dateFormat)}
                      </div>
                    </td>
                  </>
                )}
                {!orderStatus && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        item?.status === "Cancelled"
                          ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                          : "",
                        item?.status === "Rejected"
                          ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                          : "",
                        item?.status === "Pending"
                          ? `bg-[#eec96b5a] text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                          : "",
                        item?.status === "Approved"
                          ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                          : "",
                        !item?.status
                          ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                          : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>{item?.status || "Unknown"}</span>
                    </span>
                  </td>
                )}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {["Pending"].includes(orderStatus) && (
                      <>
                        <ActionButton
                          action='approve'
                          onClick={dispatchAction(item?._id, "approve")}
                        />
                        <ActionButton
                          action='reject'
                          onClick={dispatchAction(item?._id, "reject")}
                        />
                      </>
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && !!searchParams.modal?.length && (
        <>
          <RejectMaterialRequestContainer
            open={modal === "reject"}
            setOpen={(val: boolean) => setModal(val ? "reject" : undefined)}
            refetch={refetch}
          />
          <ApproveMaterialRequestContainer
            open={modal === "approve"}
            setOpen={(val: boolean) => setModal(val ? "approve" : undefined)}
            refetch={refetch}
          />
          <ViewMaterialRequestContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default MaterialRequestsPage;
