import { wrapClick } from 'utils';
import { gql, useMutation, } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, MaterialRequestForm } from 'components';

const CREATE_MATERIAL_REQUEST = gql`
  mutation CreateMaterialRequest(
    $items: [MaterialRequestItemInput!]!
  ) {
    createMaterialRequest(
      items: $items
    ) {
      _id
    }
  }
`

export default function CreateMaterialRequestContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {

  const [createMaterialRequest, { loading }] = useMutation(CREATE_MATERIAL_REQUEST)

  const form = useFormik({
    initialValues: {
      materialsInfo: {
        items: [] as {
          material: any;
          quantity: number;
        }[]
      },
    },
    onSubmit: async (values) => {
      await createMaterialRequest({
        variables: {
          items: values.materialsInfo.items.map((item) => ({
            material: item.material._id,
            quantity: item.quantity
          }))
        }
      }).then(({ data }) => {
        if (data.createMaterialRequest._id) {
          toast(JSON.stringify({ type: "success", title: "Request Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Request" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Material Request"
      description="Provide the details to add a new request"
      hideActions
      hidePadding
    >
      <MaterialRequestForm
        form={form}
      />
    </Modal>
  )
}