import { wrapClick } from 'utils';
import { gql, useMutation, } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, MaterialForm } from 'components';
import { createMaterialValidationSchema } from 'components/forms/material/schema';

const CREATE_MATERIAL = gql`
  mutation CreateMaterial(
    $code: String!
    $name: String!
    $category: MaterialCategory!
    $quantityUnit: MaterialQuantityUnit!
  ) {
    createMaterial(
      code: $code
      name: $name
      category: $category
      quantityUnit: $quantityUnit
    ) {
      _id
    }
  }
`

export default function CreateMaterialContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void}) {
 
  const [createMaterial, { loading }] = useMutation(CREATE_MATERIAL)

  const form = useFormik({
    initialValues: {
      code: "",
      name: "",
      category: "",
      quantityUnit: "",
    },
    validationSchema: createMaterialValidationSchema,
    onSubmit: async (values) => {
      await createMaterial({
        variables: {
          ...values
        }
      }).then(({ data }) => {
        if (data.createMaterial._id) {
          toast(JSON.stringify({ type: "success", title: "Material Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Material" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Material"
      description="Provide the details to add a new material"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Material..." : "Add Material"}
          </button>

        </>
      )}>
      <MaterialForm
        form={form}
      />
    </Modal>
  )
}