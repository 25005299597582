import * as Yup from 'yup';

export const createMeterModelValidationSchema = Yup.object({
  code: Yup.string().matches(/^(ML|DK)(\d{3}|\d{2})$/, "Kindly enter a valid code").required("Model code is required"),
  name: Yup.string().required("Model name is required"),
  brand: Yup.string().required("Kindly select a model brand"),
  voltage: Yup.string().required("Model voltage is required"),
  currentRating: Yup.string().required("Model current rating is required"),
  mechanism: Yup.string().required("Model mechanism is required"),
  communicationType: Yup.string().required("Communication type is required").oneOf(["NoWay", "OneWay", "TwoWay"]),
  digits: Yup.number().required("Model digits are required"),
  phase: Yup.string().required("Model phase is required"),
  type: Yup.string().required().oneOf(["Prepaid", "Postpaid", "AMR"], "Kindly choose a valid model type"),
})