import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import lodash from "lodash";

interface MeterBrandPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
  disableContractor?: boolean;
}

const GET_METER_BRANDS = gql`
  query GetMeterBrandsSelect {
    meterBrands: getMeterBrands(sort: "name", page: 0, pageSize: 0) {
      _id
      code
      name
      contractor {
        _id
      }
    }
  }
`;

const MeterBrandPickerContainer: FC<MeterBrandPickerContainerProps> = ({
  id,
  label,
  rawId,
  disableContractor,
  ...form
}) => {
  const { loading, data } = useQuery(GET_METER_BRANDS, {
    notifyOnNetworkStatusChange: false,
  });

  return (
    <SearchSelectInput
      id={id ?? "brand"}
      label={label ?? "Meter Brand"}
      placeholder="Select Meter Brand"
      optionsLoading={loading}
      options={(data?.meterBrands ?? [])
        ?.filter((item: any) => {
          if (disableContractor) {
            if (item.contractor) {
              return false;
            }
          }
          return true;
        })
        ?.map((meterBrand: any) => ({
          label: {
            title: meterBrand?.name as string,
          },
          value: rawId
            ? meterBrand?._id
            : lodash.omit(meterBrand, ["contractor"]),
        }))}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default MeterBrandPickerContainer;
