import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  MeterTransferView,
  MeterTransferTransfersView,
  Modal,
} from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_METER_TRANSFER } from "apollo/queries";
import lodash from "lodash";
import { useState } from "react";
import { wrapClick, classNames, useDownloadFile } from "utils";
import toast from "react-hot-toast";

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query($id: ID!, $status: MeterTransferTransferStatus) {
    url: getMeterTransferExportUrl(id: $id, status: $status)
  }
`;

const orderTabs = [
  {
    name: "Transfer Details",
    href: "TransferDetails",
  },
  {
    name: "Successful Transfers",
    href: "SuccessfulTransfers",
  },
  {
    name: "Failed Transfers",
    href: "FailedTransfers",
  },
];

const centralOrderTabs = [
  {
    name: "Reversal Details",
    href: "TransferDetails",
  },
  {
    name: "Meter Details",
    href: "SuccessfulTransfers",
  },
];

export default function ViewMeterTransferContainer({
  open,
  setOpen,
  isReversal,
  isCentralReversal,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  isReversal: boolean;
  isCentralReversal: boolean;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("TransferDetails");
  const { data, loading } = useQuery(GET_METER_TRANSFER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  const [createDataExport, { loading: gettingUrl }] = useLazyQuery(
    GET_SERVICE_ORDER_EXPORT_URL,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "Meter Shipment.xlsx",
  });

  const handleDownloadItem = () => {
    createDataExport({
      variables: {
        id: searchParams.id,
        status: "Failed",
      },
      fetchPolicy: "no-cache",
    }).then(({ data }) => {
      if (data.url) {
        downloadAction(`${data.url}`);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding={true}
      title={
        isReversal
          ? "Meter Reversal Informantion"
          : "Meter Transfer Information"
      }
      size="6xl"
      description={
        isReversal
          ? "Details of meter reversal are shown below"
          : "Details of meter transfer are shown below"
      }
      renderActions={() => (
        <>
          {["FailedTransfers"].includes(orderTab) && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(handleDownloadItem)}
              disabled={gettingUrl || downloadLoading}
            >
              {gettingUrl
                ? "Processing file for download"
                : downloadLoading
                ? "Downloading"
                : "Download " + lodash.startCase(orderTab)}
            </button>
          )}
        </>
      )}
    >
      {data?.meterTransfer?._id && (
        <div className="flex-1 flex flex-col overflow-hidden">
          {isCentralReversal ? (
            <>
              <div className="block">
                <div className="border-b border-gray-200 bg-white px-6">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {centralOrderTabs.map((_orderTab) => (
                      <button
                        key={_orderTab.name}
                        onClick={wrapClick(__setOrderTab(_orderTab.href))}
                        className={classNames(
                          orderTab === _orderTab.href
                            ? "border-primary-500 text-primary-600"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                          "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                        )}
                        aria-current={
                          orderTab === _orderTab.href ? "page" : undefined
                        }
                      >
                        {_orderTab.name}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="flex flex-col w-full h-[55vh] overflow-y-auto">
                {orderTab === "TransferDetails" && (
                  <MeterTransferView isCentralReversal={isCentralReversal} meterTransfer={data?.meterTransfer} />
                )}
                {orderTab === "SuccessfulTransfers" && (
                  <MeterTransferTransfersView
                    transfers={lodash.filter(data?.meterTransfer?.transfers, [
                      "status",
                      "Success",
                    ])}
                    type="Successful"
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="block">
                <div className="border-b border-gray-200 bg-white px-6">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {orderTabs.map((_orderTab) => (
                      <button
                        key={_orderTab.name}
                        onClick={wrapClick(__setOrderTab(_orderTab.href))}
                        className={classNames(
                          orderTab === _orderTab.href
                            ? "border-primary-500 text-primary-600"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                          "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                        )}
                        aria-current={
                          orderTab === _orderTab.href ? "page" : undefined
                        }
                      >
                        {_orderTab.name}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="flex flex-col w-full h-[55vh] overflow-y-auto">
                {orderTab === "TransferDetails" && (
                  <MeterTransferView isCentralReversal={isCentralReversal} meterTransfer={data?.meterTransfer} />
                )}
                {orderTab === "SuccessfulTransfers" && (
                  <MeterTransferTransfersView
                    transfers={lodash.filter(data?.meterTransfer?.transfers, [
                      "status",
                      "Success",
                    ])}
                    type="Successful"
                  />
                )}
                {orderTab === "FailedTransfers" && (
                  <MeterTransferTransfersView
                    transfers={lodash.filter(data?.meterTransfer?.transfers, [
                      "status",
                      "Failed",
                    ])}
                    type="Failed"
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
}
