import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  MeterShipmentShipmentsView,
  MeterShipmentView,
  Modal,
} from "components";
import lodash from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, classNames, useDownloadFile } from "utils";

const GET_METER_SHIPMENT = gql`
  query GetMeterShipment($id: ID!) {
    meterShipment: getMeterShipment(id: $id) {
      _id
      code
      description
      createdBy {
        _id
        code
        emailAddress
        firstName
        gender
        ghanaCardNumber
        lastName
        phoneNumber
        profileImageUrl
      }
      createdAt
      updatedAt
      serialNumbers
      endSerialNumber
      startSerialNumber
      quantity
      model {
        _id
        code
        name
        type
        phase
        digits
      }
      brand {
        _id
        code
        name
      }
      meta {
        totalShipments
        totalSuccessShipments
        totalFailedShipments
      }
      createdByType
      contractor {
        _id
        code
        name
      }
      contractorCreatedBy {
        _id
        code
        emailAddress
        firstName
        lastName
        phoneNumber
        profileImageUrl
      }
      status
      type
      shipments {
        _id
        status
        statusReason
        meterSystemDetails {
          _id
          sn
          presetAmount
          batchId
          orgId
          cosemLogicalDeviceName
          mfgSerialNumber
          customerSerialNumber
          manufacturer
          modelType
          ipAddress
          gprsModuleSerialNumber
          firmwareType
          firmwareVersion
          llsSecret
          hlsSecret
          authentication
          encryptionKey
          macAddress
          badgeId
        }
        meterCode
      }
    }
  }
`;

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query($id: ID! $status: MeterShipmentShipmentStatus) {
    url: getMeterShipmentExportUrl(id: $id status: $status)
  }
`;

const orderTabs = [
  {
    name: "Shipment Details",
    href: "ShipmentDetails",
  },
  {
    name: "Successful Shipments",
    href: "SuccessfulShipments",
  },
  {
    name: "Failed Shipments",
    href: "FailedShipments",
  },
];

export default function ViewMeterShipmentContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("ShipmentDetails");
  const { data, loading } = useQuery(GET_METER_SHIPMENT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  const [createDataExport, { loading: gettingUrl }] = useLazyQuery(
    GET_SERVICE_ORDER_EXPORT_URL,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "Failed Shipment.xlsx",
  });

  const handleDownloadItem = () => {
    createDataExport({
      variables: {
        id: searchParams.id,
        status: "Failed"
      },
      fetchPolicy: "no-cache",
    }).then(({ data }) => {
      if (data.url) {
        downloadAction(`${data.url}`);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Meter Shipment Information"
      hidePadding
      size="5xl"
      description="Details of meter shipment are shown below"
      renderActions={() => (
        <>
          {orderTab === "FailedShipments" && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(handleDownloadItem)}
              disabled={gettingUrl || downloadLoading}
            >
              {gettingUrl
                ? "Processing file for download"
                : downloadLoading
                  ? "Downloading"
                  : "Download Failed Assignments"}
            </button>
          )}
        </>
      )}
    >
      {data?.meterShipment?._id && (
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="block">
            <div className="border-b border-gray-200 bg-white px-6">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {orderTabs.map((_orderTab) => (
                  <button
                    key={_orderTab.name}
                    onClick={wrapClick(__setOrderTab(_orderTab.href))}
                    className={classNames(
                      orderTab === _orderTab.href
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                      "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={
                      orderTab === _orderTab.href ? "page" : undefined
                    }
                  >
                    {_orderTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          <div className="flex flex-col w-full h-[55vh] overflow-y-auto">
            {orderTab === "ShipmentDetails" && (
              <MeterShipmentView meterShipment={data?.meterShipment} />
            )}
            {orderTab === "SuccessfulShipments" && (
              <MeterShipmentShipmentsView
                shipments={lodash.filter(data?.meterShipment?.shipments, [
                  "status",
                  "Success",
                ])}
                status="Successful"
                type={data?.meterShipment?.type}
              />
            )}
            {orderTab === "FailedShipments" && (
              <MeterShipmentShipmentsView
                shipments={lodash.filter(data?.meterShipment?.shipments, [
                  "status",
                  "Failed",
                ])}
                status="Failed"
                type={data?.meterShipment?.type}
              />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}
