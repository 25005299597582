import { FC } from "react";
import BarLoader from "react-spinners/BarLoader";

interface LoaderProps {}

const Loader: FC<LoaderProps> = () => {
  return (
    <div className="flex-1 flex flex-col space-y-6 items-center justify-center">
      <span className="text-gray-600 text-sm">Loading details...</span>
      <BarLoader
        color={"#2DC2BD"}
        loading={true}
        cssOverride={{ width: "30%" }}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
