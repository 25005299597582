import { SearchSelectInput, TextArea, TextInput } from "components/core";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { wrapClick, classNames } from "utils";
import {
  MaterialsInfoFormSchema,
  IResolveInspectionServiceOrderFormSchema,
} from "./schema";
import _ from "lodash";
import { gql, useQuery } from "@apollo/client";
import lodash from "lodash";
import { GET_MATERIALS } from "apollo/queries";

interface MaterialsInfoFormProps {
  handleNext: (
    values: IResolveInspectionServiceOrderFormSchema["materialsInfo"]
  ) => void;
  handlePrevious: () => void;
  initialValues: IResolveInspectionServiceOrderFormSchema["materialsInfo"];
  values: IResolveInspectionServiceOrderFormSchema;
  handleCancel: () => void;
}

const MaterialsInfoForm: FC<MaterialsInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik<
    IResolveInspectionServiceOrderFormSchema["materialsInfo"]
  >({
    initialValues,
    validationSchema: MaterialsInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  const { data: dataMaterials } = useQuery(GET_MATERIALS);

  const removeItem = (field: string, id: number) => {
    form.setFieldValue(field, [
      ..._.get(form.values, field)?.filter(
        (val: string, _id: number) => _id !== id
      ),
    ]);
  };

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Shipment Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="">
              <TextInput
                id="code"
                label="Shipment Code"
                type="text"
                placeholder="e.g. Wasion"
                {...form}
              />
            </div>

            <div className="col-span-2">
              <TextInput
                id="description"
                label="Shipment Description"
                type="text"
                placeholder="e.g. Wasion"
                {...form}
              />
            </div>
          </div>
        </div>

        <div className="pt-6">
          <span className="text-xs font-light">Materials Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-3">
              <span className="block text-sm font-medium text-gray-700">
                Materials *
              </span>
              <div className="mt-1 w-full ">
                <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
                  <thead className="bg-gray-50">
                    <tr className="divide-x divide-gray-200">
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[77.5%]"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-left"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {_.times(form.values.items?.length + 1, (idx) => {
                      const id = `items.${idx}`;
                      const isLast = (form.values.items?.length || 0) === idx;

                      return (
                        <tr key={idx} className="divide-x divide-gray-200">
                          <td className="p-0">
                            <SearchSelectInput
                              label={""}
                              labelHidden={true}
                              key={idx}
                              id={`${id}.material`}
                              options={lodash
                                .chain(dataMaterials?.materials)
                                ?.map((material) => ({
                                  label: { title: material.name },
                                  value: material,
                                }))
                                .value()}
                              placeholder="eg. 1 Phase"
                              required={true}
                              className="border-0 rounded-none"
                              {...form}
                            />
                          </td>
                          <td className="p-0">
                            <input
                              key={idx}
                              type={"text"}
                              id={`${id}.quantity`}
                              name={`${id}.quantity`}
                              value={_.get(form.values, `${id}.quantity`, "")}
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                              placeholder={"eg. 2"}
                              step={1}
                              min={0}
                              className={classNames(
                                _.get(form.errors, `${id}.quantity`) &&
                                  _.get(form.touched, `${id}.quantity`)
                                  ? "focus:ring-red-500 focus:border-red-500 border-red-600 border"
                                  : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                                "block w-full sm:text-sm placeholder:font-light placeholder:text-xs h-[38px] border-0 focus:border text-left"
                              )}
                            />
                          </td>
                          <td className="p-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400 text-right w-[38px] h-[38px] items-center justify-center flex">
                            {!isLast && (
                              <button
                                type="button"
                                onClick={wrapClick(() =>
                                  removeItem("items", idx)
                                )}
                                className="inline-flex items-center rounded-full border border-transparent p-1 text-red-500 hover:bg-red-300 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              >
                                <XMarkIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default MaterialsInfoForm;
