import { FC } from "react";
import { useUrlState } from "utils";

import _ from "lodash";
import { Header } from "components";
import { RegionPicker, DistrictPicker } from "containers";
import { currentUserVar } from "apollo/cache/auth";
import { useReactiveVar } from "@apollo/client";

interface OfficeHeaderProps {
  renderActions?: FC<{}>;
}

const OfficeHeader: FC<OfficeHeaderProps> = ({ renderActions }) => {
  const currentUser = useReactiveVar(currentUserVar);
  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState("district");

  return (
    <Header
      renderActions={() => (
        <div className="flex items-center space-x-3">
          {currentUser.region && (
            <RegionPicker
              id="region"
              labelHidden={true}
              setFieldValue={(_field: string, value: string) => {
                setRegion(value||undefined);
                setDistrict(undefined);
              }}
              values={{ region }}
              rawId={true}
            />
          )}
          {currentUser.district && (
            <DistrictPicker
              id="district"
              labelHidden={true}
              setFieldValue={(_field: string, value: string) =>
                setDistrict(value||undefined)
              }
              values={{ district }}
              filter={{ region }}
              rawId={true}
            />
          )}
          {renderActions && (
            <>
              <div className="h-6 w-px bg-gray-300" />
              {renderActions?.({})}
            </>
          )}
        </div>
      )}
    />
  );
};

export default OfficeHeader;
