import { gql, useQuery } from "@apollo/client";
import { Header, SelectInput, Shimmers, TableComponent } from "components";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, withPermissions, useTableData } from "utils";
import config from "config";
import { PlusIcon } from "@heroicons/react/24/outline";
import CreateMeterBrandContainer from "./create";
import UpdateMeterBrandContainer from "./update";
import ViewMeterBrandContainer from "./view";
import { ExportDataContainer } from "containers";
import ActionButton, { Action } from "components/buttons/action-button";
import _ from "lodash";

const GET_METER_BRANDS = gql`
  query GetMeterBrands(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    rows: getMeterBrands(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
      _id
      code
      name
      createdAt
      updatedAt
    }
    count: getMeterBrandsCount(search: $search, searchFields: $searchFields)
  }
`;

const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 4,
  },
  {
    label: "Name",
    value: "name",
    min: 4,
  },
];

const MeterBrandsPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");
  const [, setViewType] = useUrlState("view-type", "grid");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_METER_BRANDS, {
    variables: filter,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-full bg-gray-50'>
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "meter-brands:*", "meter-brands:create"])(
              <button
                type='button'
                onClick={wrapClick(() => setModal("create"))}
                className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                <PlusIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                Add New Meter Brand
              </button>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"meter brands"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading}
            data={records}
            hasSearch={true}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            defaultView={"grid"}
            renderGridLoader={() => (
              <div className='overflow-hidden rounded-lg bg-white shadow'>
                <div className='flex pb-8 px-4 pt-4'>
                  <div className='rounded-md h-12 w-12 bg-gray-400' />
                  <dd className='ml-4 flex-1 flex items-center'>
                    <Shimmers.DoubleShimmer />
                  </dd>
                </div>
                <div className=' bg-gray-50 px-3 py-1.5 flex justify-between sm:px-4 space-x-10'>
                  <Shimmers.SingleShimmer />
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </div>
              </div>
            )}
            renderGridItem={(item) => (
              <div
                key={item?._id}
                className='relative overflow-hidden rounded-lg bg-white shadow'
              >
                <div className='p-4 flex justify-between items-center space-x-3'>
                  <dd className='flex flex-col items-baseline'>
                    <p className='truncate text-sm font-medium text-gray-500'>
                      {item?.code}
                    </p>
                    <p className='font-normal text-gray-900 line-clamp-1'>
                      {item?.name}
                    </p>
                  </dd>
                  <div>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions([
                      "*:*",
                      "meter-brands:*",
                      "meter-brands:update",
                    ])(
                      <ActionButton
                        action='update'
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>

      {withPermissions(["*:*", "meter-brands:*", "meter-brands:create"])(
        <CreateMeterBrandContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}
      {!!searchParams.id?.length && !!searchParams.modal?.length && (
        <>
          {withPermissions(["*:*", "meter-brands:*", "meter-brands:update"])(
            <UpdateMeterBrandContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewMeterBrandContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default MeterBrandsPage;
