import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  MeterOrderShipmentShipmentsView,
  MeterOrderShipmentView,
  Modal,
} from "components";
import lodash from "lodash";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, classNames, useDownloadFile } from "utils";

const GET_CONTRACTOR_INSTALLATION_SHIPMENT = gql`
  query GetInstallationServiceOrderAssignment($id: ID!) {
    meterShipment: getInstallationServiceOrderAssignment(id: $id) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      assignments {
        _id
        sn
        batchId
        orgId
        cosemLogicalDeviceName
        mfgSerialNumber
        customerSerialNumber
        manufacturer
        modelType
        ipAddress
        gprsModuleSerialNumber
        firmwareType
        firmwareVersion
        llsSecret
        hlsSecret
        authentication
        encryptionKey
        macAddress
        badgeId
        status
        statusReason
      }
      status
      meta {
        totalSuccessAssignments
        totalFailedAssignments
        totalAssignments
      }
      createdBy {
        _id
        code
        lastName
        firstName
        gender
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      contractor {
        _id
        code
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const GET_CONTRACTOR_REPLACEMENT_SHIPMENT = gql`
  query GetReplacementServiceOrderAssignment($id: ID!) {
    meterShipment: getReplacementServiceOrderAssignment(id: $id) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      assignments {
        _id
        sn
        batchId
        orgId
        cosemLogicalDeviceName
        mfgSerialNumber
        customerSerialNumber
        manufacturer
        modelType
        ipAddress
        gprsModuleSerialNumber
        firmwareType
        firmwareVersion
        llsSecret
        hlsSecret
        authentication
        encryptionKey
        macAddress
        badgeId
        status
        statusReason
      }
      status
      meta {
        totalSuccessAssignments
        totalFailedAssignments
        totalAssignments
      }
      createdBy {
        _id
        code
        lastName
        firstName
        gender
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      contractor {
        _id
        code
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONTRACTOR_INSTALLATION_SHIPMENT_EXPORT_URL = gql`
  query Query($id: ID!) {
    url: getInstallationServiceOrderShipmentExportUrl(id: $id)
  }
`;

export const GET_CONTRACTOR_REPLACEMENT_SHIPMENT_EXPORT_URL = gql`
  query Query($id: ID!) {
    url: getReplacementServiceOrderShipmentExportUrl(id: $id)
  }
`;

const orderTabs = [
  {
    name: "Shipment Details",
    href: "ShipmentDetails",
  },
  {
    name: "Meter Details",
    href: "Meters",
  },
];

export default function ViewOrderMeterShipmentContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("ShipmentDetails");
  const QUERY = useMemo(
    () =>
      searchParams?.section === "InstallationShipment"
        ? GET_CONTRACTOR_INSTALLATION_SHIPMENT
        : GET_CONTRACTOR_REPLACEMENT_SHIPMENT,
    [searchParams?.section]
  );
  const EXPORT_QUERY = useMemo(
    () =>
      searchParams?.section === "InstallationShipment"
        ? GET_CONTRACTOR_INSTALLATION_SHIPMENT_EXPORT_URL
        : GET_CONTRACTOR_REPLACEMENT_SHIPMENT_EXPORT_URL,
    [searchParams?.section]
  );
  const { data, loading } = useQuery(QUERY, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  const [createDataExport, { loading: gettingUrl }] = useLazyQuery(
    EXPORT_QUERY,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "Meter Shipment.xlsx",
  });

  const handleDownloadItem = () => {
    createDataExport({
      variables: {
        id: searchParams.id,
      },
      fetchPolicy: "no-cache",
    }).then(({ data }) => {
      if (data.url) {
        downloadAction(`${data.url}`);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding={true}
      title="Meter Shipment Information"
      size="6xl"
      description="Details of meter shipment are shown below"
      renderActions={() => (
        <>
          {orderTab === "Meters" && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(handleDownloadItem)}
              disabled={gettingUrl || downloadLoading}
            >
            {gettingUrl
              ? "Processing file for download"
              : downloadLoading
              ? "Downloading"
              : "Download Shipment"}
            </button>
          )}
        </>
      )}
    >
      {data?.meterShipment?._id && (
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="block">
            <div className="border-b border-gray-200 bg-white px-6">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {orderTabs.map((_orderTab) => (
                  <button
                    key={_orderTab.name}
                    onClick={wrapClick(__setOrderTab(_orderTab.href))}
                    className={classNames(
                      orderTab === _orderTab.href
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                      "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={
                      orderTab === _orderTab.href ? "page" : undefined
                    }
                  >
                    {_orderTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          <div className="flex flex-col w-full h-[55vh] overflow-y-auto">
            {orderTab === "ShipmentDetails" && (
              <MeterOrderShipmentView meterShipment={data?.meterShipment} />
            )}
            {orderTab === "Meters" && (
              <MeterOrderShipmentShipmentsView
                shipments={lodash.filter(data?.meterShipment?.assignments, [
                  "status",
                  "Success",
                ])}
                type="Successful"
              />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}
