import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MeterTransferForm } from "components";

const CREATE_METER_TRANSFER = gql`
  mutation CreateMeterTransfer(
    $model: ID!
    $serialNumbers: [String!]!
    $region: ID
    $district: ID
    $isReversal: Boolean
  ) {
    createMeterTransfer(
      model: $model
      serialNumbers: $serialNumbers
      region: $region
      district: $district
      isReversal: $isReversal
    ) {
      _id
    }
  }
`;

export default function CreateMeterTransferContainer({
  open,
  setOpen,
  refetch,
  isReversal,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
  isReversal: boolean;
}) {
  const [createMeterTransfer] = useMutation(CREATE_METER_TRANSFER);

  const form = useFormik({
    initialValues: {
      metersInfo: {
        region: null as any,
        district: null as any,
        brand: null as any,
        model: null as any,
        serialNumbers: [] as string[],
        isReversal,
      },
    },
    onSubmit: async (values) => {
      await createMeterTransfer({
        variables: {
          ...values.metersInfo,
          region: values.metersInfo.region?._id,
          district: values.metersInfo.district?._id,
          brand: values.metersInfo.brand?._id,
          model: values.metersInfo.model?._id,
          isReversal,
        },
      }).then(({ data }) => {
        if (data.createMeterTransfer._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Transfer Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Transfer",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={isReversal ? "Start Meter Reversal" : "Start Meter Transfer"}
      description={
        isReversal
          ? "Provide the details to reverse meters"
          : "Provide the details to transfer meters"
      }
      hideActions
      hidePadding
      size="5xl"
    >
      <MeterTransferForm form={form} isReversal={isReversal} />
    </Modal>
  );
}
