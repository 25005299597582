import { wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MeterModelForm } from "components";
import { CREATE_METER_MODEL } from "apollo/mutations";
import { createMeterModelValidationSchema } from "components/forms/meter-model/schema";

export default function CreateMeterModelContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createMeterModel, { loading }] = useMutation(CREATE_METER_MODEL);

  const form = useFormik({
    initialValues: {
      code: "",
      name: "",
      brand: "",
      voltage: "",
      currentRating: "",
      mechanism: "",
      communicationType: "",
      digits: 6,
      phase: "1 phase",
      type: "Prepaid" as "Prepaid" | "Postpaid" | "AMR",
    },
    validationSchema: createMeterModelValidationSchema,
    onSubmit: async (values) => {
      await createMeterModel({
        variables: {
          ...values,
        },
      }).then(({ data }) => {
        if (data.createMeterModel._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Meter Model Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Meter Model",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Meter Model"
      description="Provide the details to add a new model"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Meter Model..." : "Add Meter Model"}
          </button>
        </>
      )}
    >
      <MeterModelForm form={form} />
    </Modal>
  );
}
