import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { RejectMaterialRequestForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const REJECT_MATERIAL_REQUEST = gql`
  mutation RejectMaterialRequest(
    $id: ID!
    $reason: String!
  ) {
    rejectMaterialRequest(
      id: $id
      reason: $reason
    ) {
      _id
    }
  }
`

export default function RejectMaterialRequestContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
 const searchParams = useSearch<LocationGenerics>();
  const [rejectMaterialRequest, { loading }] = useMutation(REJECT_MATERIAL_REQUEST)

  const form = useFormik({
    initialValues: {
      reason: "",
    },
    onSubmit: async (values) => {
      await rejectMaterialRequest({
        variables: {
          id: searchParams.id,
          ...values
        }
      }).then(({ data }) => {
        if (data.rejectMaterialRequest._id) {
          toast(JSON.stringify({ type: "success", title: "Request Rejected Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not approve Request" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Reject Request"
      description="Provide reason for rejecting material requisition"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Rejecting Request..." : "Reject Request"}
          </button>
        </>
      )}>
      <RejectMaterialRequestForm
        form={form}
      />
    </Modal>
  )
}