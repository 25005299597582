import { gql, useQuery } from "@apollo/client";
import { MeterView, Modal, RemovedMeterView } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const GET_METER = gql`
query GetReplacementServiceOrder($id: ID!) {
  scrappedMeter: getReplacementServiceOrder(id: $id) {
    _id
    code
    category
    assigneeType
    replacementType
    replacementReason
    oldMeterNumber
    oldMeterPhase
    customerCode
    servicePointCode
    accountCode
    status
    debtStatus
    priority
    region {
      name
    }
    district {
      name
    }
    meter {
      _id
      code
      batchCode
      modelType
      status
      balance
      balanceUpdatedAt
      meterCode
      location
      createdAt
      updatedAt
      modelMeta {
        phase
        modelCode
        modelName
        brandCode
        brandName
        systemCode
        systemName
        digits
        voltage
        currentRating
        mechanism
        communicationType
      }
    }
    retiredMeterStatus
  }
}

`;

export default function ViewMeterContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_METER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      title='Meter Information'
      description='Details of meter are shown below'
      size='5xl'
    >
      {data?.scrappedMeter && <RemovedMeterView meter={data?.scrappedMeter} />}
    </Modal>
  );
}
