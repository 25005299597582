import { DistrictCodes, ServiceClasses, ServiceTypes } from 'apollo/data';
import lodash from 'lodash';
import * as Yup from 'yup';
export type ServiceType = typeof ServiceTypes[number]["value"];
export type ServiceClass = typeof ServiceClasses[number]["value"];

export interface IResolveInspectionServiceOrderFormSchema {
  materialsInfo: {
    code: string;
    description: string;
    items: {
      material: {
        _id: string;
        code: string;
        name: string;
        quantityUnit: string;
      };
      quantity: number;
    }[];
  }
};

export const MaterialsInfoFormSchema = Yup.object().shape({
  code: Yup.string().required("Shipment code is required"),
  description: Yup.string().required("Kindly enter the shipment description"),
  items: Yup.array().of(Yup.object().shape({
    material: Yup.object().shape({
      _id: Yup.string().required(),
      code: Yup.string().required(),
      name: Yup.string().required(),
      quantityUnit: Yup.string().required(),
    }).required(),
    quantity: Yup.number().min(0).required()
  }).required()).required().min(1, "Kindly enter at least one material"),
})

export const ResolveInspectionServiceOrderFormSchema = Yup.object().shape({
  materialsInfo: MaterialsInfoFormSchema.required()
})