import { gql, useQuery } from "@apollo/client";
import { MeterView, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

const GET_METER = gql`
  query GetMeter($id: ID!) {
    meter: getMeter(id: $id) {
      _id
      code
      batchCode
      brand {
        _id
        code
        name
      }
      model {
        _id
        code
        name
        phase
        type
        digits
        voltage
        currentRating
        mechanism
        communicationType
        createdAt
        updatedAt
      }
      servicePoint {
        _id
        code
        qrCode
        geoLocation {
          type
          coordinates
        }
        property {
          _id
          code
          qrCode
          address
          ghanaPostAddress
          streetName
          houseNumber
          createdAt
          updatedAt
        }
        propertyCode
        customer {
          _id
          code
          customerType
          organization {
            name
          }

          representative {
            title
            fullName
            nationality
            dateOfBirth
            gender
            phoneNumber
            emailAddress
            profileImageUrl
          }
          status
          createdAt
          updatedAt
        }
        customerCode
        account {
          _id
          code
          status
          balance
        }
        accountCode
        serviceType
        serviceClass
        tariffClass {
          _id
          code
          name
        }
        geoCode
        activity {
          _id
          code
          name
        }
        subActivity {
          _id
          code
          name
        }
        status
        meta {
          lastBillAmount
          lastBillDate
          lastReadingValue
          lastReadingDate
        }
        createdAt
        updatedAt
      }
      status
      balance
      balanceUpdatedAt
      meta {
        lastReadingValue
        lastReadingDate
      }
      location
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      createdAt
      updatedAt
      modelMeta {
        modelCode
        modelName
        phase
        digits
        voltage
        currentRating
        mechanism
        communicationType
      }
    }
  }
`;

export default function ViewMeterContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_METER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      title='Meter Information'
      description='Details of meter are shown below'
      size='5xl'
    >
      {data?.meter && <MeterView meter={data?.meter} />}
    </Modal>
  );
}
