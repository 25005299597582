import { useReactiveVar } from "@apollo/client";
import { SelectInput, Shimmers, TableComponent } from "components";
import _ from "lodash";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  classNames,
  wrapNumber,
  useTableData,
} from "utils";
import config from "config";
import ActionButton, { Action } from "components/buttons/action-button";
import OfficeHeader from "components/layouts/office-header";
import { currentUserVar } from "apollo/cache/auth";
import { useRemovedMeters } from "./hooks";
import ReceiveReplacementRetiredMeterContainer from "./receive";
import ProcessReplacementRetiredMeterContainer from "./process";
import ScrapReplacementRetiredMeterContainer from "./scrap";
import RefurbishReplacementRetiredMeterContainer from "./refurbish";
import ReshipReplacementRetiredMeterContainer from "./reship";
import ViewReplacementServiceOrderContainer from "./view";
import moment from "moment";

const locations = [
  {
    name: "Pending Processing",
    href: "PendingProcessing",
    withPermissions: [""],
  },
  {
    name: "To Be Scrapped",
    href: "ToBeScrapped",
    withPermissions: [],
  },
  {
    name: "Scrapped",
    href: "Scrapped",
    withPermissions: [],
  },
  { name: "To Be Refurbished", href: "ToBeRefurbished", withPermissions: [] },
  { name: "Refurbished", href: "Refurbished", withPermissions: [] },
  { name: "To Be Reshipped", href: "ToBeReshipped", withPermissions: [] },
  { name: "Reshipped", href: "Reshipped", withPermissions: [] },
];

const searchOptions = [
  { label: "Service Point No.", value: "servicePointCode", min: 5 },
  { label: "Meter No.", value: "oldMeterNumber", min: 5 },
];
const RemovedMetersPage: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  // const [model, setModel] = useUrlState("model");
  // const [brand, setBrand] = useUrlState("brand");
  const [retiredMeterStatus] = useUrlState("location");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      status: "Completed",
      // sort: searchParams.sort || "",
      retiredMeterStatus: searchParams.location || "PendingReceipt",
    }),
    [searchParams]
  );

  const { removedMeters, loading, networkStatus, refetch, summary, count } =
    useRemovedMeters(filter);
  console.log("removedMeters", removedMeters);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData({ rows: removedMeters, count: count });

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <OfficeHeader />
      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav className='-mb-px flex space-x-4 items-center' aria-label='Tabs'>
            <Link
              search={(old) => ({
                ...old,
                location: undefined,
              })}
              className={classNames(
                !retiredMeterStatus
                  ? "border-primary-500 text-primary-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!retiredMeterStatus ? "page" : undefined}
            >
              Pending Receipt
              <span
                className={classNames(
                  !retiredMeterStatus
                    ? "bg-primary-100 text-primary-600"
                    : "bg-gray-100 text-gray-900",
                  "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                )}
              >
                {wrapNumber(_.sum(_.values(_.omit(summary, "__typename"))))}
              </span>
            </Link>
            <div className='h-6 w-px bg-gray-300' />
            {locations.map((_location) => (
              <Link
                key={_location.name}
                search={(old) => ({
                  ...old,
                  location: _location.href,
                })}
                className={classNames(
                  retiredMeterStatus === _location.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  retiredMeterStatus === _location.href ? "page" : undefined
                }
              >
                {_location.name}
                {_location.href ? (
                  <span
                    className={classNames(
                      retiredMeterStatus === _location.href
                        ? "bg-primary-100 text-primary-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {wrapNumber(_.get(summary, _location.href) || 0)}
                  </span>
                ) : null}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"meters"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="Meter"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Meter Number
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  SPN
                </th>

                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Type
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Phase
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Removed At
                </th>

                {!currentUser.region && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Region
                  </th>
                )}
                {!currentUser.district && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    District
                  </th>
                )}

                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                {!currentUser.region && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                {!currentUser.district && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}

                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.meter?.code || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-left'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.servicePointCode || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.meter?.modelType || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-left'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.meter?.modelMeta?.phase || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-left'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.completedAt
                      ? moment(item?.completedAt).format("DD-MM-YYYY")
                      : "N/A"}
                  </div>
                </td>
                {!currentUser.region && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.region?.name || "N/A"}
                    </div>
                  </td>
                )}
                {!currentUser.district && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.district?.name || "N/A"}
                    </div>
                  </td>
                )}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    {item?.retiredMeterStatus === "PendingReceipt" && (
                      <ActionButton
                        action='approve'
                        tooltip='Mark as received'
                        onClick={dispatchAction(item?._id, "approve")}
                      />
                    )}
                    {item?.retiredMeterStatus === "PendingProcessing" && (
                      <ActionButton
                        action='resolve'
                        tooltip='Process'
                        onClick={dispatchAction(item?._id, "resolve")}
                      />
                    )}
                    {item?.retiredMeterStatus === "ToBeScrapped" && (
                      <ActionButton
                        action='scrap'
                        tooltip='Process'
                        onClick={dispatchAction(item?._id, "scrap")}
                      />
                    )}
                    {item?.retiredMeterStatus === "ToBeRefurbished" && (
                      <ActionButton
                        action='refurbish'
                        tooltip='Process'
                        onClick={dispatchAction(item?._id, "refurbish")}
                      />
                    )}
                    {item?.retiredMeterStatus === "ToBeReshipped" && (
                      <ActionButton
                        action='reship'
                        tooltip='Process'
                        onClick={dispatchAction(item?._id, "reship")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && !!searchParams.modal?.length && (
        <>
          <ViewReplacementServiceOrderContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <ReceiveReplacementRetiredMeterContainer
            open={modal === "approve"}
            setOpen={(val: boolean) => setModal(val ? "approve" : undefined)}
          />
          <ProcessReplacementRetiredMeterContainer
            open={modal === "resolve"}
            setOpen={(val: boolean) => setModal(val ? "resolve" : undefined)}
          />
          <ScrapReplacementRetiredMeterContainer
            open={modal === "scrap"}
            setOpen={(val: boolean) => setModal(val ? "scrap" : undefined)}
          />
          <RefurbishReplacementRetiredMeterContainer
            open={modal === "refurbish"}
            setOpen={(val: boolean) => setModal(val ? "refurbish" : undefined)}
          />
          <ReshipReplacementRetiredMeterContainer
            open={modal === "reship"}
            setOpen={(val: boolean) => setModal(val ? "reship" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default RemovedMetersPage;
