import { wrapClick } from 'utils';
import { gql, useMutation, } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, RefurbishReplacementRetiredMeterForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

const CREATE_MATERIAL = gql`
mutation RefurbishReplacementRetiredMeter(
  $refurbishReplacementRetiredMeterId: ID!
  $notes: String
) {
  refurbishReplacementRetiredMeter(
    id: $refurbishReplacementRetiredMeterId
    notes: $notes
  ) {
    _id
  }
}

`

export default function RefurbishReplacementRetiredMeterContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
    const searchParams = useSearch<LocationGenerics>()
    const [createMaterial, { loading }] = useMutation(CREATE_MATERIAL)

    const form = useFormik({
        initialValues: {
            notes: ""
        },
        onSubmit: async (values) => {
            await createMaterial({
                variables: {
                    ...values,
                    refurbishReplacementRetiredMeterId: searchParams?.id || ""
                }
            }).then(({ data }) => {
                if (data.refurbishReplacementRetiredMeter._id) {
                    toast(JSON.stringify({ type: "success", title: "Meter refurbished successfully" }));
                    refetch?.();
                    form.resetForm();
                }
                else {
                    toast(JSON.stringify({ type: "error", title: "Could not refurbish Meter" }))
                }
            })
        },
        onReset: () => {
            setOpen(false)
        }
    })

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Refurbish Replacement Retired Meter"
            description="Please fill out the form below to refurbish a replacement retired meter."
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Saving changes..." : "Save Changes"}
                    </button>

                </>
            )}>
            <RefurbishReplacementRetiredMeterForm
                form={form}
            />
        </Modal>
    )
}