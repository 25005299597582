export default [
  { name: "", value: "*:*" },

  { name: "", value: "meter-models:*" },
  { name: "", value: "meter-models:manage" },
  { name: "", value: "meter-models:create" },
  { name: "", value: "meter-models:update" },
  { name: "", value: "meter-models:import" },
  { name: "", value: "meter-models:delete" },

  { name: "", value: "meter-systems:*" },
  { name: "", value: "meter-systems:manage" },
  { name: "", value: "meter-systems:create" },
  { name: "", value: "meter-systems:update" },
  { name: "", value: "meter-systems:import" },
  { name: "", value: "meter-systems:delete" },

  { name: "", value: "meter-brands:*" },
  { name: "", value: "meter-brands:manage" },
  { name: "", value: "meter-brands:create" },
  { name: "", value: "meter-brands:update" },
  { name: "", value: "meter-brands:import" },
  { name: "", value: "meter-brands:delete" },

  { name: "", value: "material-outgoing-requests:*" },
  { name: "", value: "material-outgoing-requests:manage" },
  { name: "", value: "material-outgoing-requests:create" },
  { name: "", value: "material-outgoing-requests:cancel" },

  { name: "", value: "material-incoming-requests:*" },
  { name: "", value: "material-incoming-requests:manage" },
  { name: "", value: "material-incoming-requests:approve" },
  { name: "", value: "material-incoming-requests:reject" },
  
  { name: "", value: "material-shipments:*" },
  { name: "", value: "material-shipments:manage" },
  { name: "", value: "material-shipments:create" },
  { name: "", value: "material-shipments:update" },
  { name: "", value: "material-shipments:import" },
  { name: "", value: "material-shipments:delete" },

  { name: "", value: "materials:*" },
  { name: "", value: "materials:manage" },
  { name: "", value: "materials:create" },
  { name: "", value: "materials:update" },
  { name: "", value: "materials:import" },
  { name: "", value: "materials:delete" },

  { name: "", value: "meter-outgoing-requests:*" },
  { name: "", value: "meter-outgoing-requests:manage" },
  { name: "", value: "meter-outgoing-requests:create" },
  { name: "", value: "meter-outgoing-requests:cancel" },

  { name: "", value: "meter-incoming-requests:*" },
  { name: "", value: "meter-incoming-requests:manage" },
  { name: "", value: "meter-incoming-requests:approve" },
  { name: "", value: "meter-incoming-requests:reject" },

  { name: "", value: "meters:*" },
  { name: "", value: "meters:manage" },
  { name: "", value: "meters:create" },
  { name: "", value: "meters:update" },
  { name: "", value: "meters:import" },
  { name: "", value: "meters:delete" },

  { name: "", value: "meter-shipments:*" },
  { name: "", value: "meter-shipments:manage" },
  { name: "", value: "meter-shipments:create" },
  { name: "", value: "meter-shipments:update" },
  { name: "", value: "meter-shipments:import" },
  { name: "", value: "meter-shipments:delete" },
] as const;
