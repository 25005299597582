import * as Yup from 'yup';

export interface IMeterRequestFormSchema {
  metersInfo: {
    region: {
      _id: string;
      code: string;
      name: string;
    };
    district: {
      _id: string;
      code: string;
      name: string;
    };
    brand: {
      _id: string;
      code: string;
      name: string;
    };
    model: {
      _id: string;
      code: string;
      name: string;
    };
    serialNumbers: string[];
    isReversal: boolean
  }
};


export const MetersInfoFormSchema = Yup.object().shape({
  region: Yup.object().nullable().notRequired(),
  district: Yup.object().nullable().notRequired(),
  brand: Yup.object().nullable().required("Kindly choose a meter brand"),
  model: Yup.object().nullable().required("Kindly choose a meter model"),
  serialNumbers: Yup.array().of(Yup.string()).required("Kindly enter the meter serial numbers").min(1, "Kindly enter at least one serial number"),
})

export const MeterRequestFormSchema = Yup.object().shape({
  metersInfo: MetersInfoFormSchema.required()
})