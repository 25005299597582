import numeral from 'numeral';
import { FC } from 'react'

interface MaterialViewProps {
  material: {
    quantity: string;
    storeQuantity: string;
    minQuantity: any;
    maxQuantity: any;
    code: string;
    name: string;
    category: string;
    quantityUnit: string;
    
  };
}

const MaterialView: FC<MaterialViewProps> = ({ material }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Material Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {material?.code || "N/A"}
            </div>
          </div>

          <div className=''>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {material?.name || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Category
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {material?.category || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Unit
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {material?.quantityUnit || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Global Quanity
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {material?.quantity ? numeral(material.quantity).format("0,0") : "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Store Quantity
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {material?.storeQuantity ? numeral(material.storeQuantity).format("0,0") : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Minimum Quantity
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {material?.minQuantity ? numeral(material.minQuantity).format("0,0") : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Maximum Quantity
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {material?.maxQuantity ? numeral(material.maxQuantity).format("0,0") : "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaterialView