import { withPermissions, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { MaterialRequestView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_MATERIAL_REQUEST } from "apollo/queries";

export default function ViewMaterialRequestContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_MATERIAL_REQUEST, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const dispatchAction = (action: "approve" | "reject") => () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: action,
      }),
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Material Request Information"
      description="Details of material request are shown below"
      renderActions={() => (
        <>
          {data?.materialRequest?.status === "Pending" && (
            <>
              {withPermissions([
                "*:*",
                "material-incoming-requests:*",
                "material-incoming-requests:approve",
              ])(
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={wrapClick(dispatchAction("approve"))}
                >
                  Approve
                </button>
              )}
              {withPermissions([
                "*:*",
                "material-incoming-requests:*",
                "material-incoming-requests:reject",
              ])(
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={wrapClick(dispatchAction("reject"))}
                >
                  Reject
                </button>
              )}
            </>
          )}
        </>
      )}
    >
      {data?.materialRequest && (
        <MaterialRequestView
          displayRequester={true}
          materialRequest={data?.materialRequest}
        />
      )}
    </Modal>
  );
}
