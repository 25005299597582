import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import ReadingInfoSummary from "./reading-info-summary";
import ReplacementMaterialInfoSummary from "./materials-info-summary";
import { FC } from "react";

interface InstallationServiceOrderResolutionViewProps {
  resolution: any;
  result: string;
}

const InstallationServiceOrderResolutionView: FC<
  InstallationServiceOrderResolutionViewProps
> = ({ resolution, result }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      
      <div className="">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Meter Infomation
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about meter taken before removal
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <ReadingInfoSummary
            data={{
              readingDate: resolution?.previousReading?.readingDate,
              readingValue: resolution?.previousReading?.readingValue,
              readingImageUrl: resolution?.previousReading?.readingImageUrl,
              notes: resolution?.notes,
            }}
            type={"Final"}
            config={currentConfig}
          />
        </div>
      </div>
      <div className="pt-6 ">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Materials Infomation
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about materials used for the installation
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <ReplacementMaterialInfoSummary
            data={{
              materials: resolution?.materials,
              recoveredMaterials: resolution?.recoveredMaterials,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InstallationServiceOrderResolutionView;
