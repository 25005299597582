import { FC } from "react";
import { IMeterRequestFormSchema } from "./schema";
import numeral from "numeral";
import { useReactiveVar } from "@apollo/client";
import { currentUserVar } from "apollo/cache/auth";

const MeterInfoSummary: FC<{
  data: IMeterRequestFormSchema["metersInfo"];
  isReversal: boolean;
}> = ({ data, isReversal }) => {
  const currentUser = useReactiveVar(currentUserVar);

  return (
    <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
      <div className="">
        <span className="text-xs font-light">Meters Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          {isReversal ? (
            <div>
              <span className="block text-sm font-light text-gray-700">
                Destination
              </span>
              <div className="mt-1 block w-full sm:text-sm">Central Store</div>
            </div>
          ) : (
            <div>
              {currentUser?.region ? (
                <>
                  <span className="block text-sm font-light text-gray-700">
                    Destination
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.district?.name || "N/A"}
                  </div>
                </>
              ) : (
                <>
                  <span className="block text-sm font-light text-gray-700">
                    Destination
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {data?.region?.name || "N/A"}
                  </div>
                </>
              )}
            </div>
          )}
          <div className="col-start-1">
            <span className="block text-sm font-light text-gray-700">
              Meter Brand
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.brand?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Model
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.model?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Quantity
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(data?.serialNumbers?.length).format("0,0") || "N/A"}{" "}
              Meters
            </div>
          </div>
          {/* <div className="col-start-1">
            <span className="block text-sm font-light text-gray-700">
              Start Serial Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.startSerialNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              End Serial Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.endSerialNumber || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Quantity
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(data?.quantity).format("0,0") || "N/A"} Meters
            </div>
          </div> */}
          <div className="col-start-1 col-span-3">
            <span className="block text-sm font-light text-gray-700">
              Serial Numbers
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.serialNumbers?.join(", ") || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterInfoSummary;
