import { Route } from "react-location";
import {
  DashboardPage,
  ForgotPasswordPage,
  SigninPage,
  AuthPage,
  MetersPage,
  MeterTransfers,
  MaterialsPage,
  MaterialIncomingRequisitions,
  MaterialOutgoingRequisitions,
  MeterModelsPage,
  MeterSystemsPage,
  MeterShipmentsPage,
  MaterialShipmentsPage,
  MeterBrandsPage,
  RemovedMetersPage,
  ScrappedMetersPage,
  RefurbishedMetersPage,
  MMSMeterShipmentsPage,
  ServiceRegularizationsPage,
  ShepRegularizationsPage,
} from "pages";
import {
  ChartBarSquareIcon,
  LifebuoyIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { UserPermission } from "apollo/cache/auth";
import CalendarLayout from "layouts/calendar-wrapper";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <CalendarLayout />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "General",
    },
    children: [
      {
        id: "all",
        path: "/",
        element: <DashboardPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
    ],
  },
  {
    path: "meters",
    element: <MetersPage />,
    sidebar: {
      label: "Meters",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meters",
      section: "Meters",
    },
    withPermissions: ["*:*", "meters:*", "meters:manage"],
  },
  {
    path: "removed-meters",
    element: <RemovedMetersPage />,
    sidebar: {
      label: "Removed Meters",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Removed Meters",
      section: "Scrap Yard",
    },
    withPermissions: ["*:*", "meters:*", "meters:manage"],
  },
  {
    path: "scrapped-meters",
    element: <ScrappedMetersPage />,
    sidebar: {
      label: "Scrapped Meters",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Scrapped Meters",
      section: "Scrap Yard",
    },
    withPermissions: ["*:*", "meters:*", "meters:manage"],
  },
  {
    path: "refurbished-meters",
    element: <RefurbishedMetersPage />,
    sidebar: {
      label: "Refurbished Meters",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Refurbished Meters",
      section: "Scrap Yard",
    },
    withPermissions: ["*:*", "meters:*", "meters:manage"],
  },
  {
    path: "meter-shipments",
    element: <MeterShipmentsPage />,
    sidebar: {
      label: "Shipments",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meter Shipments",
      section: "Meters",
    },
    withPermissions: ["*:*", "meter-shipments:*", "meter-shipments:manage"],
  },
  {
    path: "mms-shipments",
    element: <MMSMeterShipmentsPage />,
    sidebar: {
      label: "MMS Shipments",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "MMS Shipments",
      section: "Meters",
    },
    withPermissions: ["*:*", "meter-shipments:*", "meter-shipments:manage"],
  },
  {
    path: "meter-inbound-transfers",
    element: <MeterTransfers type='Incoming' />,
    sidebar: {
      label: "Inbound Transfers",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Inbound Transfers",
      section: "Meters",
    },
    withPermissions: [
      "*:*",
      "meter-incoming-requests:*",
      "meter-incoming-requests:manage",
    ],
  },
  {
    path: "meter-outbound-transfers",
    element: <MeterTransfers type='Outgoing' />,
    sidebar: {
      label: "Outbound Transfers",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Outbound Transfers",
      section: "Meters",
    },
    withPermissions: [
      "*:*",
      "meter-outgoing-requests:*",
      "meter-outgoing-requests:manage",
    ],
  },
  {
    path: "meter-reversals",
    element: <MeterTransfers type='Reversal' />,
    sidebar: {
      label: "Reversals",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Reversals",
      section: "Meters",
    },
    withPermissions: [
      "*:*",
      "meter-outgoing-requests:*",
      "meter-outgoing-requests:manage",
    ],
  },
  {
    path: "materials",
    element: <MaterialsPage />,
    sidebar: {
      label: "Materials",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Materials",
      section: "Materials",
    },
    withPermissions: ["*:*", "materials:*", "materials:manage"],
  },
  {
    path: "material-shipments",
    element: <MaterialShipmentsPage />,
    sidebar: {
      label: "Shipments",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Material Shipments",
      section: "Materials",
    },
    withPermissions: [
      "*:*",
      "material-shipments:*",
      "material-shipments:manage",
    ],
  },
  {
    path: "material-incoming-requisitions",
    element: <MaterialIncomingRequisitions />,
    sidebar: {
      label: "Incoming Requisitions",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Incoming Requisitions",
      section: "Materials",
    },
    withPermissions: [
      "*:*",
      "material-incoming-requests:*",
      "material-incoming-requests:manage",
    ],
  },
  {
    path: "material-outgoing-requisitions",
    element: <MaterialOutgoingRequisitions />,
    sidebar: {
      label: "Outgoing Requisitions",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Outgoing Requisitions",
      section: "Materials",
    },
    withPermissions: [
      "*:*",
      "material-outgoing-requests:*",
      "material-outgoing-requests:manage",
    ],
  },
  {
    path: "meter-brands",
    element: <MeterBrandsPage />,
    sidebar: {
      label: "Meter Brands",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meter Brands",
      section: "Settings",
    },
    withPermissions: ["*:*", "meter-brands:*", "meter-brands:manage"],
  },
  {
    path: "meter-models",
    element: <MeterModelsPage />,
    sidebar: {
      label: "Meter Models",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meter Models",
      section: "Settings",
    },
    withPermissions: ["*:*", "meter-models:*", "meter-models:manage"],
  },
  {
    path: "meter-systems",
    element: <MeterSystemsPage />,
    sidebar: {
      label: "Meter Systems",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Meter Systems",
      section: "Settings",
    },
    withPermissions: ["*:*", "meter-systems:*", "meter-systems:manage"],
  },
  {
    path: "shep-regularizations",
    element: <ShepRegularizationsPage />,
    sidebar: {
      label: "SHEP Captures",
      icon: LifebuoyIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "SHEP Captures",
      section: "Regularizations",
    },

    //TODO: Resolve right permissions
    withPermissions: ["*:*", "meter-models:*", "meter-models:manage"],
  },
  {
    path: "service-regularizations",
    element: <ServiceRegularizationsPage />,
    sidebar: {
      label: "Regularizations",
      icon: UserCircleIcon,
    },

    meta: {
      layout: "App",
      breadcrumb: () => "Regularizations",
      section: "Regularizations",
    },

    //TODO: Verify and enable permisions
    withPermissions: ["*:*", "meter-models:*", "meter-models:manage"],
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
  {
    path: "auth",
    element: <AuthPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
];

export default routes;
