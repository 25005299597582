import _ from "lodash";
import lodash from "lodash";
import { FC } from "react";

export interface RemovedMeterViewProps {
  meter: RemovedMeter
}

export interface RemovedMeter {
  _id: string
  code: string
  category: string
  assigneeType: string
  replacementType: string
  replacementReason: string
  oldMeterNumber: string
  oldMeterPhase: string
  customerCode: string
  servicePointCode: string
  accountCode: string
  status: string
  debtStatus: string
  priority: string
  region: Region
  district: District
  meter: Meter
  retiredMeterStatus: string
  __typename: string
}

export interface Region {
  name: string
  __typename: string
}

export interface District {
  name: string
  __typename: string
}

export interface Meter {
  _id: string
  code: string
  batchCode: any
  modelType: string
  status: string
  balance: any
  balanceUpdatedAt: any
  meterCode: string
  location: string
  createdAt: string
  updatedAt: string
  modelMeta: ModelMeta
  __typename: string
}

export interface ModelMeta {
  phase: string
  modelCode: string
  modelName: string
  brandCode: string
  brandName: string
  systemCode: string
  systemName: string
  digits: any
  voltage: string
  currentRating: string
  mechanism: any
  communicationType: any
  __typename: string
}



const RemovedMeterView: FC<RemovedMeterViewProps> = ({ meter }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Meter Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.code || "N/A"}
            </div>
          </div>

          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              Batch Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.meter?.batchCode || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Location
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {lodash.startCase(meter?.meter?.location) || "N/A"}
            </div>
          </div>

          {meter.region && (
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Region
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {meter?.region?.name || "N/A"}
              </div>
            </div>
          )}

          {meter.district && (
            <div>
              <span className='block text-sm font-light text-gray-700'>
                District
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {meter?.district?.name || "N/A"}
              </div>
            </div>
          )}

          {meter.district && (
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Meter Status
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {_.startCase(meter?.meter?.status) || "N/A"}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Meter Model Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Brand
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.meter?.modelMeta?.brandCode || "N/A"} - {meter?.meter?.modelMeta?.brandName || "N/A"}
            </div>
          </div>

          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              Model
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.meter?.modelMeta?.modelCode || "N/A"} - {meter?.meter?.modelMeta?.modelName || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              System
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.meter?.modelMeta?.systemCode || "N/A"} - {meter?.meter?.modelMeta?.systemName || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>
          Meter Model Specification Information
        </span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Type
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.meter?.modelType || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Communication Type
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {lodash.startCase(meter?.meter?.modelMeta?.communicationType) || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Phase
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.meter?.modelMeta?.phase || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Mechanism
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.meter?.modelMeta?.mechanism || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Digits
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.meter?.modelMeta?.digits || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Voltage
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.meter?.modelMeta?.voltage || "N/A"}V
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Current Rating
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.meter?.modelMeta?.currentRating || "N/A"}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default RemovedMeterView;
