import { gql } from "@apollo/client";

export const CREATE_METER_MODEL = gql`
  mutation CreateMeterModel(
    $digits: PositiveInt!
    $phase: String!
    $type: MeterModelType!
    $code: String!
    $name: String!
    $brand: ID!
    $voltage: String!
    $currentRating: String!
    $mechanism: MeterModelMechanism!
    $communicationType: MeterModelCommunicationType!
  ) {
    createMeterModel(
      digits: $digits
      phase: $phase
      type: $type
      code: $code
      name: $name
      brand: $brand
      voltage: $voltage
      currentRating: $currentRating
      mechanism: $mechanism
      communicationType: $communicationType
    ) {
      _id
    }
  }
`;

export const UPDATE_MATERIAL = gql`
  mutation UpdateMaterial(
    $id: ID!
    $code: String!
    $name: String!
    $category: MaterialCategory!
    $quantityUnit: MaterialQuantityUnit!
  ) {
    updateMaterial(
      id: $id
      code: $code
      name: $name
      category: $category
      quantityUnit: $quantityUnit
    ) {
      _id
    }
  }
`;
