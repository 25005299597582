
import { FC } from 'react'
import { Link, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon } from '@heroicons/react/24/outline'
import { classNames } from 'utils';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import lodash from 'lodash';
import { currentUserVar } from 'apollo/cache/auth';

const stats = [
  { id: 1, name: "Inspections", accessor: "inspection", icon: UsersIcon },
  {
    id: 2,
    name: "Installations",
    accessor: "installation",
    icon: EnvelopeOpenIcon,
  },
  {
    id: 3,
    name: "Disconnections",
    accessor: "disconnection",
    icon: CursorArrowRaysIcon,
  },
  { id: 4, name: "Reconnections", accessor: "reconnection", icon: UsersIcon },
  {
    id: 5,
    name: "Investigations",
    accessor: "investigation",
    icon: EnvelopeOpenIcon,
  },
  {
    id: 6,
    name: "Replacements",
    accessor: "replacement",
    icon: CursorArrowRaysIcon,
  },
];

const SERVICE_ORDER_SUMMARY = gql`
  query GetServiceOrdersSummary($region: ID, $district: ID) {
    inspection: getInspectionServiceOrderSummary(
      region: $region
      district: $district
    ) {
      Assigned
      Completed
      InProgress
      Pending
      Resolved
    }
    installation: getInstallationServiceOrderSummary(
      region: $region
      district: $district
    ) {
      Assigned
      Completed
      InProgress
      Pending
      Resolved
    }
    investigation: getInvestigationServiceOrderSummary(
      region: $region
      district: $district
    ) {
      Assigned
      Completed
      InProgress
      Pending
      Resolved
    }
    replacement: getReplacementServiceOrderSummary(
      region: $region
      district: $district
    ) {
      Assigned
      Completed
      InProgress
      Pending
      Resolved
    }
    reconnection: getReconnectionServiceOrderSummary(
      region: $region
      district: $district
    ) {
      Assigned
      Completed
      InProgress
      Pending
      Resolved
    }
    disconnection: getDisconnectionServiceOrderSummary(
      region: $region
      district: $district
    ) {
      Assigned
      Completed
      InProgress
      Pending
      Resolved
    }
  }
`;

const DashboardPage: FC = () => {

  const currentUser = useReactiveVar(currentUserVar);
  const searchParams = useSearch<LocationGenerics>()
  const { data }: any = { data: {} };//useQuery(SERVICE_ORDER_SUMMARY)

  return (
    <div className="flex-1 min-h-full mx-auto  p-4 sm:p-6 overflow-y-auto bg-gray-50">
      <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">
        <div className="col-span-12">
          <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            <div className="relative isolate overflow-hidden rounded-lg bg-primary-400 px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6 row-span-2">
              <div className="absolute inset-y-0 right-8 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-15deg] bg-primary-500 shadow-xl shadow-primary-400 ring-1 ring-primary-400 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
              <div className="absolute inset-y-0 right-24 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-20deg] bg-primary-600 shadow-xl shadow-primary-500 ring-1 ring-primary-500 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
              <div className="absolute inset-0 -z-10 bg-gradient-to-b from-transparent via-primary-600/80 to-primary-600" />
              <div className="mx-auto max-w-2xl lg:max-w-4xl">
                <span className='text-white text-xl font-extralight'>
                  Welcome back <span className='font-semibold'>{currentUser?.firstName}</span>
                </span>
              </div>
            </div>
            {false && stats.map((item) => (
              <div
                key={item.id}
                className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
              >
                <dt>
                  <div className="absolute rounded-md bg-primary-500 p-3">
                    <item.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">
                    {item.name}
                  </p>
                </dt>
                <dd className="ml-16 flex justify-between items-end pb-6 sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {lodash
                      .chain(data?.[item.accessor])
                      ?.omit(["__typename"])
                      ?.toPairs()
                      ?.map(([key, value]) => value)
                      ?.sum()
                      ?.value()}
                  </p>
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      data?.[item.accessor]?.Pending ?? 0 > 0
                        ? "bg-primary-100 text-primary-800"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    {data?.[item.accessor]?.Pending ?? 0} Pending
                  </span>
                  <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <Link
                        to={`/${item.accessor}s`}
                        className="font-medium text-primary-600 hover:text-primary-500"
                      >
                        {" "}
                        View all
                        <span className="sr-only"> {item.name}  View all</span>
                      </Link>
                    </div>
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
