import { FC, useEffect } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MeterBrandForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_METER_BRAND } from "./view";
import { createMeterBrandValidationSchema } from "components/forms/meter-brand/schema";

const UPDATE_METER_MODEL = gql`
  mutation UpdateMeterBrand(
    $id: ID!
    $code: String!
    $name: String!
    $contractor: ID
    $system: ID
  ) {
    updateMeterBrand(
      id: $id
      code: $code
      name: $name
      contractor: $contractor
      system: $system
    ) {
      _id
    }
  }
`;

interface IUpdateMeterBrandContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const UpdateMeterBrandContainer: FC<IUpdateMeterBrandContainerProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_METER_BRAND, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateMeterBrand, { loading }] = useMutation(UPDATE_METER_MODEL);

  const form = useFormik({
    initialValues: {
      code: "",
      name: "",
      contractor: "",
      system: "",
    },
    validationSchema: createMeterBrandValidationSchema, 
    onSubmit: async (values) => {
      await updateMeterBrand({
        variables: {
          id: searchParams.id,
          code: values.code,
          name: values.name,
          ...(values.contractor?.length ? { contractor: values.contractor } : {}),
          ...(values.system?.length ? { system: values.system } : {}),
        },
      }).then(({ data }) => {
        if (data.updateMeterBrand._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Meter Brand Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Meter Brand",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      code: data?.meterBrand?.code || "",
      name: data?.meterBrand?.name || "",
      contractor: data?.meterBrand?.contractor?._id || "",
      system: data?.meterBrand?.system?._id || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.meterBrand, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Meter Brand Information"
      description="Provide the details to update meter brand"
      size="2xl"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing Meter Brand..." : "Edit Meter Brand"}
          </button>
        </>
      )}
    >
      {data?.meterBrand?._id && <MeterBrandForm form={form} />}
    </Modal>
  );
};

export default UpdateMeterBrandContainer;
