import { gql, useQuery } from "@apollo/client";

const GET_REMOVED_METERS = gql`
  query GetReplacementServiceOrders(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $sort: String
    $retiredMeterStatus: ReplacementRetiredMeterStatus
  ) {
    count: getReplacementServiceOrdersCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      retiredMeterStatus: $retiredMeterStatus
    )
    rows: getReplacementServiceOrders(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      sort: $sort
      retiredMeterStatus: $retiredMeterStatus
    ) {
      _id
      code
      category
      assigneeType
      replacementType
      replacementReason
      oldMeterNumber
      oldMeterPhase
      customerCode
      servicePointCode
      accountCode
      status
      debtStatus
      priority
      region {
        name
      }
      district {
        name
      }
      meter {
        _id
        code
        batchCode
        modelType
        status
        balance
        balanceUpdatedAt
        meterCode
        location
        createdAt
        updatedAt
      }
      retiredMeterStatus
    }
  }
`;

export const useScrappedMeters = (variables?: any) => {
  const { data, ...rest } = useQuery(GET_REMOVED_METERS, {
    variables: {
      ...variables,
      retiredMeterStatus: "Scrapped",
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return {
    removedMeters: data?.rows || [],
    summary: {},
    count: data?.count || 0,
    ...rest,
  };
};
