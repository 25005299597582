import * as Yup from "yup";

export interface IMeterShipmentFormSchema {
  shipmentInfo: {
    brand: {
      _id: string;
      code: string;
      name: string;
    };
    model: {
      _id: string;
      code: string;
      name: string;
    };
    serialNumbers: string[];
    description: string;
    code: string;
  };
  metersInfo: {
    meterSystemDetails: {
      sn: string;
      presetAmount: number;
      batchId: string;
      orgId: string;
      cosemLogicalDeviceName: string;
      mfgSerialNumber: string;
      customerSerialNumber: string;
      manufacturer: string;
      modelType: string;
      ipAddress: string;
      gprsModuleSerialNumber: string;
      firmwareType: string;
      firmwareVersion: string;
      llsSecret: string;
      hlsSecret: string;
      authentication: string;
      encryptionKey: string;
      macAddress: string;
      badgeId: string;
    }[];
  };
}

export const ShipmentInfoFormSchema = Yup.object().shape({
  brand: Yup.object().nullable().required("Meter brand is required"),
  model: Yup.object().nullable().required("Meter model is required"),
  serialNumbers: Yup.array().of(Yup.string()).required("Kindly enter the meter serial numbers."),
  description: Yup.string().required("Shipment description is required"),
  code: Yup.string().required("Shipment code is required"),
});

export const MetersInfoFormSchema = Yup.object().shape({
  meterSystemDetails: Yup
    .array()
    .of(
      Yup.object().shape({
        sn: Yup.string().notRequired(),
        presetAmount: Yup.number().typeError("Preset Amount Must be a number").required("Preset Amount is Required"),
        batchId: Yup.string().required("Batch ID is Required"),
        orgId: Yup.string().required("Organization ID is Required"),
        cosemLogicalDeviceName: Yup.string()
          .required("Cosem Logical device Name is Required"),
        mfgSerialNumber: Yup.string().required("MGF Serial Number is Required"),
        customerSerialNumber: Yup.string()
          .required("Customer Serial Number is Required"),
        manufacturer: Yup.string().required("Manufacturer is Required"),
        modelType: Yup.string().required("Model Type is Required"),
        ipAddress: Yup.string().required("IP Address is Required"),
        gprsModuleSerialNumber: Yup.string()
          .required("GPRS Module Serial Number is Required"),
        firmwareType: Yup.string().notRequired(),
        firmwareVersion: Yup.string().required("Firmware Version is Required"),
        llsSecret: Yup.string().notRequired(),
        hlsSecret: Yup.string().notRequired(),
        authentication: Yup.string().notRequired(),
        encryptionKey: Yup.string().notRequired(),
        macAddress: Yup.string().notRequired(),
        badgeId: Yup.string().required("Badge ID is Required"),
      })
    )
    .min(1, "Should contain at least one record"),
});

export const MeterShipmentFormSchema = Yup.object().shape({
  metersInfo: MetersInfoFormSchema.required(),
});
