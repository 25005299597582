import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { FC, useEffect } from "react";
import { SearchSelectInput } from "components/core";
import { currentUserVar } from "apollo/cache/auth";
import lodash from "lodash";

interface RegionPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
}

const GET_REGIONS = gql`
  query GetRegionsSelect {
    regions: getRegions(sort: "name" page: 0 pageSize: 0) {
      _id
      code
      name
    }
  }
`;

const RegionPickerContainer: FC<RegionPickerContainerProps> = ({
  id,
  label,
  rawId,
  ...form
}) => {
  const currentUser = useReactiveVar(currentUserVar);
  const { loading, data } = useQuery(GET_REGIONS, {
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    if (currentUser?.region) {
      if (
        !lodash.isEqual(
          rawId
            ? currentUser.region?._id
            : {
                _id: currentUser.region._id,
                code: currentUser.regionCode,
                name: currentUser.regionName,
              },
          lodash.get(form.values, id)
        )
      ) {
        form?.setFieldValue?.(
          id,
          rawId
            ? currentUser.region?._id
            : {
                _id: currentUser.region._id,
                code: currentUser.regionCode,
                name: currentUser.regionName,
              }
        );
      }
    }
  }, [currentUser]);

  return (
    <>
      <SearchSelectInput
        id={id ?? "region"}
        label={label ?? "Region"}
        placeholder="Select Region"
        optionsLoading={loading}
        options={(data?.regions ?? [])?.map((region: any) => ({
          label: {
            title: region.name as string,
          },
          value: rawId ? region?._id : region,
        }))}
        {...form}
        disabled={form.disabled || !!currentUser.region}
      />
    </>
  );
};

export default RegionPickerContainer;
