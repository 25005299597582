import { FC, useEffect } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MaterialForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_MATERIAL } from "apollo/queries";
import { UPDATE_MATERIAL } from "apollo/mutations";
import { createMaterialValidationSchema } from "components/forms/material/schema";

interface IUpdateMaterialContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const UpdateMaterialContainer: FC<IUpdateMaterialContainerProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_MATERIAL, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateMaterial, { loading }] = useMutation(UPDATE_MATERIAL);

  const form = useFormik({
    initialValues: {
      code: "",
      name: "",
      category: "",
      quantityUnit: "",
    },
    validationSchema: createMaterialValidationSchema,
    onSubmit: async (values) => {
      await updateMaterial({
        variables: {
          id: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.updateMaterial._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Material Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not update Material",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      code: data?.material?.code || "",
      name: data?.material?.name || "",
      category: data?.material?.category || "",
      quantityUnit: data?.material?.quantityUnit || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.material, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Material Information"
      description="Provide the details to update meter model"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing Material..." : "Edit Material"}
          </button>
        </>
      )}
    >
      {data?.material?._id && <MaterialForm form={form} />}
    </Modal>
  );
};

export default UpdateMaterialContainer;
