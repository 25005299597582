import { useMemo, useState } from "react";
import { classNames, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import {
  ReplacementServiceOrderResolutionView,
  Modal,
  ReplacementServiceOrderView,
} from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { Action } from "components/buttons/action-button";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Disclosure } from "@headlessui/react";
import { Tooltip } from "react-tooltip";

export const GET_SERVICE_ORDER = gql`
  fragment ServiceOrderPrepaidSync on PrepaidSync {
    _id
    status
    stage
    completedAt
    data {
      servicePointCode
      regionCode
      customer {
        surname
        otherNames
        idNo
        idType
        taxRefNo
        address1
        address2
        address3
        telephone1
        telephone2
        telephone3
        fax
        email
      }
      servicePoint {
        address1
        address2
        address3
        geoCode
        digitalAddress
      }
      tariffClass {
        tariffCode
        tariffGroup
      }
      meter {
        meterSerialNo
        batchNo
        make
        model
        phase
        systemDetails
      }
      debt {
        debtType
        debtAmount
        debtRef
        instalmentDueDate
        debtStatus
      }
    }
    history {
      action
      data
      message
      timestamp
      status
    }
  }

  fragment MeterDetail on Meter {
    _id
    code
    modelMeta {
      brandCode
      brandName
      systemCode
      systemName
      modelCode
      modelName
      phase
    }
    syncMethod
  }

  query GetReplacementServiceOrder($id: ID!) {
    replacementServiceOrder: getReplacementServiceOrder(id: $id) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      farmingOutContractor {
        _id
        code
        name
        contactPerson {
          fullName
          phoneNumber
          emailAddress
        }
      }
      farmingOutContractorUser {
        _id
        code
        username
        firstName
        lastName
        phoneNumber
        emailAddress
      }
      assigner {
        _id
        lastName
        firstName
        phoneNumber
        emailAddress
        profileImageUrl
      }
      contractorAssigner {
        _id
        lastName
        firstName
        phoneNumber
        emailAddress
        profileImageUrl
      }
      replacementReason
      resolution {
        property {
          poleNumber
          propertyImageUrls
        }
        service {
          qrCode
          meter {
            ...MeterDetail
          }
        }
        reverseSyncMeter {
          ...MeterDetail
        }
        reverseSyncReading {
          readingDate
          readingValue
        }
        previousReading {
          readingDate
          readingValue
          readingImageUrl
        }
        currentReading {
          readingDate
          readingValue
          readingImageUrl
        }
        notes
        materials {
          material {
            _id
            code
            name
            category
            quantityUnit
            quantity
            storeQuantity
            createdAt
            updatedAt
          }
          quantity
        }
        recoveredMaterials {
          material {
            _id
            code
            name
            category
            quantityUnit
            quantity
            storeQuantity
            createdAt
            updatedAt
          }
          quantity
        }
      }
      status
      priority
      history {
        actor {
          ... on Contractor {
            _id
            name
            code
          }
          ... on ContractorUser {
            _id
            firstName
            lastName
            username
            code
          }
          ... on MeterContractor {
            _id
            name
            code
          }
          ... on MeterContractorUser {
            _id
            firstName
            lastName
            code
          }
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        assignee {
          ... on Contractor {
            _id
            name
            code
          }
          ... on ContractorUser {
            _id
            firstName
            lastName
            username
            code
          }
          ... on MeterContractor {
            _id
            name
            code
          }
          ... on MeterContractorUser {
            _id
            firstName
            lastName
            code
          }
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        actorType
        assigneeType
        timestamp
        action
        notes
      }
      notes
      estimatedResolutionDate
      servicePoint {
        _id
        code
        geoCode
        tariffClass {
          _id
          code
          name
        }
      }
      replacementType
      meter {
        ...MeterDetail
      }
      installationMeter {
        ...MeterDetail
      }
      installedMeter {
        ...MeterDetail
      }
      retiredMeter {
        ...MeterDetail
      }
      retiredMeterStatus
      installationMeterSystemSyncStatus
      installationMeterSystemSyncMethod
      installationSync {
        ...ServiceOrderPrepaidSync
      }
      uninstallationSync {
        ...ServiceOrderPrepaidSync
      }
      presetAmountSync {
        ...ServiceOrderPrepaidSync
      }
      assignedAt
      contractorAssignedAt
      startedAt
      resolvedAt
      contractorResolvedAt
      completedAt
      rejectedAt
      createdAt
      reverseSyncedAt
      updatedAt
      rejectionReason
      result
      oldMeterNumber
      oldMeterPhase
    }
  }
`;

const orderTabs = [
  {
    name: "Order Details",
    href: "OrderDetails",
    activeStatues: [
      "AssignedToSupplier",
      "AssignedToInstaller",
      "Resolved",
      "Completed",
      "InProgress",
    ],
  },
  {
    name: "Resolution Details",
    href: "ResolutionDetails",
    activeStatues: ["Resolved", "Completed"],
  },
];

export default function ViewReplacementServiceOrderContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("OrderDetails");
  const { data, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });
  const dispatchAction =
    (action: Exclude<Action, "expand" | "goto" | "clone">) => () => {
      navigate({
        search: (old) => ({
          ...old,
          modal: action,
        }),
      });
    };
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate({
          search: (old) => ({
            ...old,
            modal: undefined,
            id: undefined,
          }),
        });
      }}
      hideActions={false}
      hidePadding={true}
      loading={loading}
      title="Retired Meter Information"
      size="4xl"
      description="Details of retired meter are shown below"
      renderActions={() => (
        <>
        {data?.replacementServiceOrder?.retiredMeterStatus ===
          "PendingReceipt" && (
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(dispatchAction("approve"))}
          >
            Mark as received
          </button>
        )}
        {data?.replacementServiceOrder?.retiredMeterStatus ===
          "PendingProcessing" && (
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(dispatchAction("resolve"))}
          >
            Process
          </button>
        )}
        {data?.replacementServiceOrder?.retiredMeterStatus ===
          "ToBeScrapped" && (
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(dispatchAction("scrap"))}
          >
            Process
          </button>
        )}
        {data?.replacementServiceOrder?.retiredMeterStatus ===
          "ToBeRefurbished" && (
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(dispatchAction("refurbish"))}
          >
            Process
          </button>
        )}
        {data?.replacementServiceOrder?.retiredMeterStatus ===
          "ToBeReshipped" && (
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(dispatchAction("reship"))}
          >
            Process
          </button>
        )}
        </>
      )}
    >
      {data?.replacementServiceOrder?._id && (
        <>
         <div className="block">
              <div className="border-b border-gray-200 bg-white px-6">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {orderTabs.map((_orderTab) => (
                    <button
                      key={_orderTab.name}
                      onClick={wrapClick(__setOrderTab(_orderTab.href))}
                      disabled={
                        !_orderTab.activeStatues.includes(
                          data?.replacementServiceOrder?.status
                        )
                      }
                      className={classNames(
                        orderTab === _orderTab.href
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                        "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                      aria-current={
                        orderTab === _orderTab.href ? "page" : undefined
                      }
                    >
                      {_orderTab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          <div className="flex-1 w-full min-h-[65vh] max-h-[65vh] overflow-y-auto  sm:p-6">
            {orderTab === "OrderDetails" && (
              <ReplacementServiceOrderView
                replacementServiceOrder={data?.replacementServiceOrder}
              />
            )}
            {orderTab === "ResolutionDetails" && (
              <ReplacementServiceOrderResolutionView
                resolution={{
                  ...data?.replacementServiceOrder?.resolution,
                  installedMeter: data?.replacementServiceOrder?.installedMeter,
                }}
                result={data?.replacementServiceOrder?.result}
              />
            )}
          </div>
        </>
      )}
    </Modal>
  );
}
