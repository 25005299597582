import { FC } from 'react'

interface MeterSystemViewProps {
  meterSystem: {
    code: string;
    name: string;
  };
}

const MeterSystemView: FC<MeterSystemViewProps> = ({ meterSystem }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Meter System Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterSystem?.code || "N/A"}
            </div>
          </div>

          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterSystem?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeterSystemView