import { gql, useQuery } from "@apollo/client";
import { Header, SelectInput, Shimmers, TableComponent } from "components";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  wrapClick,
  useUrlState,
  withPermissions,
  classNames,
  useTableData,
} from "utils";
import config from "config";
import {
  PlusIcon,
  BuildingStorefrontIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import CreateMeterModelContainer from "./create";
import UpdateMeterModelContainer from "./update";
import ViewMeterModelContainer from "./view";
import { ExportDataContainer } from "containers";
import ActionButton, { Action } from "components/buttons/action-button";
import _ from "lodash";

const GET_METER_MODELS = gql`
  query GetMeterModels(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $brand: ID
  ) {
    rows: getMeterModels(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      brand: $brand
    ) {
      _id
      code
      digits
      phase
      type
      createdAt
      updatedAt
      communicationType
      currentRating
      mechanism
      name
      voltage
      brand {
        _id
        code
        name
      }
    }
    count: getMeterModelsCount(
      search: $search
      searchFields: $searchFields
      brand: $brand
    )
  }
`;

const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 4,
  },
  {
    label: "Name",
    value: "name",
    min: 4,
  },
];

const MeterModelsPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");
  const [, setViewType] = useUrlState("view-type", "grid");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      brand: searchParams.brand || undefined,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(GET_METER_MODELS, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-full bg-gray-50'>
      <Header
        renderActions={() => (
          <>
            {withPermissions(["*:*", "meter-models:*", "meter-models:create"])(
              <button
                type='button'
                onClick={wrapClick(() => setModal("create"))}
                className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
              >
                <PlusIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                Add New Meter Model
              </button>
            )}
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"meter models"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            defaultView={"grid"}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="MeterModel"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Brand
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Model
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Digits
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Phase
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Type
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.code}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-500 dark:text-gray-100'>
                    {item?.brand?.name || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-400'>
                    {item?.name || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.digits || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.phase || "N/A"} phase
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-400'>
                    {item?.type || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions([
                      "*:*",
                      "meter-models:*",
                      "meter-models:update",
                    ])(
                      <ActionButton
                        action='update'
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
            renderGridLoader={() => (
              <div className='overflow-hidden rounded-lg bg-white shadow'>
                <div className='flex pb-8 px-4 pt-4'>
                  <div className='rounded-md h-12 w-12 bg-gray-400' />
                  <dd className='ml-4 flex-1 flex items-center'>
                    <Shimmers.DoubleShimmer />
                  </dd>
                </div>
                <div className=' bg-gray-50 px-3 py-1.5 flex justify-between sm:px-4 space-x-10'>
                  <Shimmers.SingleShimmer />
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </div>
              </div>
            )}
            renderGridItem={(item) => (
              <div
                key={item?._id}
                className='relative overflow-hidden rounded-lg bg-white shadow'
              >
                <div className='p-4 flex space-x-3'>
                  <dt>
                    <div
                      className={classNames(
                        item?.type === "Prepaid" ? "bg-cyan-600" : "",
                        item?.type === "Postpaid" ? "bg-primary-600" : "",
                        "rounded-md p-3"
                      )}
                    >
                      {item?.type === "Prepaid" && (
                        <HomeIcon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      )}
                      {item?.type === "Postpaid" && (
                        <BuildingStorefrontIcon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      )}
                    </div>
                  </dt>
                  <dd className='flex flex-col items-baseline space-x-2'>
                    <p className='truncate text-sm font-medium text-gray-500'>
                      {item?.code}
                    </p>
                    <p className='font-normal text-gray-900 line-clamp-1'>
                      {item?.brand?.name} - {item?.name}
                    </p>
                    <p className='text-sm text-gray-500 line-clamp-1'>
                      {item?.phase} Phase : {item?.digits} Digits
                    </p>
                  </dd>
                </div>
                <div className='w-full bg-gray-50 px-3 py-3 flex justify-between sm:px-4'>
                  <span
                    className={classNames(
                      item?.type === "Prepaid"
                        ? `bg-cyan-100 text-cyan-800 dark:bg-cyan-600 dark:text-gray-50`
                        : "",
                      item?.type === "Postpaid"
                        ? `bg-primary-100 text-primary-800 dark:bg-primary-600 dark:text-gray-50`
                        : "",
                      "inline-flex rounded-md  px-3 py-0 text-xs items-center space-x-1"
                    )}
                  >
                    <span>{_.startCase(item?.type || "Unknown")}</span>
                  </span>

                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions([
                      "*:*",
                      "meter-models:*",
                      "meter-models:update",
                    ])(
                      <ActionButton
                        action='update'
                        onClick={dispatchAction(item?._id, "update")}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>

      {withPermissions(["*:*", "meter-models:*", "meter-models:create"])(
        <CreateMeterModelContainer
          open={modal === "create"}
          setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
          refetch={refetch}
        />
      )}
      {!!searchParams.id?.length && !!searchParams.modal?.length && (
        <>
          {withPermissions(["*:*", "meter-models:*", "meter-models:update"])(
            <UpdateMeterModelContainer
              open={modal === "update"}
              setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
              refetch={refetch}
            />
          )}
          <ViewMeterModelContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default MeterModelsPage;
