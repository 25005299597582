import { TextArea } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

interface RefurbishReplacementRetiredMeterFormProps {
    form: FormikProps<{
        notes: string;
    }>;
}

const RefurbishReplacementRetiredMeterForm: FC<RefurbishReplacementRetiredMeterFormProps> = ({ form }) => {

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className="text-xs font-light">Refurbish Information</span>
                <div className="grid gap-6 mt-2">
                    <div>
                        <TextArea
                            id="notes"
                            label="Refurbish Notes"
                            placeholder="e.g. Details captured are conclusive"
                            rows={10}
                            {...form}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default RefurbishReplacementRetiredMeterForm