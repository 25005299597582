import { gql } from "@apollo/client";

export const GET_MATERIAL_SHIPMENTS = gql`
  query GetMaterialShipments(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
  ) {
    rows: getMaterialShipments(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
    ) {
      _id
      code
      description
      createdBy {
        _id
        code
        emailAddress
        firstName
        gender
        ghanaCardNumber
        lastName
        phoneNumber
        profileImageUrl
      }
      createdAt
      updatedAt
    }
    count: getMaterialShipmentsCount(
      search: $search
      searchFields: $searchFields
    )
  }
`;

export const GET_MATERIALS = gql`
  query GetAllMaterials {
    materials: getMaterials(sort: "name", page: 0, pageSize: 0) {
      _id
      code
      name
      quantityUnit
      createdAt
      updatedAt
    }
  }
`;

export const GET_MATERIAL_REQUESTS = gql`
  query GetMaterialRequests(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $status: MaterialRequestStatus
  ) {
    rows: getMaterialRequests(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
    ) {
      _id
      code
      items {
        quantity
      }
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      createdBy {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      approvedBy {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      cancelledBy {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      rejectedBy {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      status
      rejectedAt
      createdAt
      updatedAt
      type
      cancelledAt
      approvedAt
    }
    count: getMaterialRequestsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
    )
  }
`;

export const GET_MATERIAL_REQUEST_SUMMARY = gql`
  query GetMaterialRequestSummary($region: ID, $district: ID) {
    getMaterialRequestSummary(region: $region, district: $district) {
      Pending
      Approved
      Cancelled
      Rejected
    }
  }
`;

export const GET_MATERIAL_REQUEST = gql`
  query GetMaterialRequest($id: ID!) {
    materialRequest: getMaterialRequest(id: $id) {
      _id
      code
      items {
        material {
          _id
          code
          name
          quantityUnit
        }
        quantity
      }
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      createdBy {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      approvedBy {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      cancelledBy {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      rejectedBy {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      status
      rejectedAt
      rejectedBecause
      type
      cancelledAt
      cancelledBecause
      approvedAt
      approvalNotes
      createdAt
      updatedAt
    }
  }
`;

export const GET_MATERIAL = gql`
  query GetMaterial($id: ID!) {
    material: getMaterial(id: $id) {
      _id
      code
      name
      category
      quantityUnit
      quantity
      storeQuantity
      minQuantity
      maxQuantity
      
      createdAt
      updatedAt
    }
  }
`;

export const GET_METER_BRAND = gql`
  query GetMeterBrand($id: ID!) {
    meterBrand: getMeterBrand(id: $id) {
      _id
      code
      name
      contractor {
        _id
        code
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_METER_MODEL_MODEL = gql`
  query GetMeterModel($id: ID!) {
    meterModel: getMeterModel(id: $id) {
      _id
      code
      digits
      type
      phase
      createdAt
      updatedAt
      brand {
        _id
        code
        name
      }
      system {
        _id
        code
        name
      }
      communicationType
      currentRating
      mechanism
      name
      voltage
    }
  }
`;

export const GET_METER_TRANSFER = gql`
  query GetMeterTransfer($id: ID!) {
    meterTransfer: getMeterTransfer(id: $id) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      createdBy {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      type
      notes
      createdAt
      updatedAt
      brand {
        _id
        code
        name
      }
      model {
        _id
        code
        name
        type
        phase
        voltage
        currentRating
      }
      startSerialNumber
      endSerialNumber
      quantity
      meta {
        totalTransfers
        totalSuccessTransfers
        totalFailedTransfers
      }
      status
      transfers {
        _id
        meterCode
        status
        statusReason
      }
    }
  }
`;

export const GET_METER_TRANSFERS = gql`
  query GetMeterTransfers(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $type: MeterTransferDirection
  ) {
    rows: getMeterTransfers(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      type: $type
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      createdBy {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      model {
        _id
        code
        name
      }
      brand {
        _id
        code
        name
      }
      startSerialNumber
      endSerialNumber
      quantity
      createdAt
      updatedAt
      type
    }
    count: getMeterTransfersCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      type: $type
    )
  }
`;
