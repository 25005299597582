import * as Yup from 'yup';

export interface IResolveInspectionServiceOrderFormSchema {
  materialsInfo: {
    items: {
      material: {
        _id: string;
        code: string;
        name: string;
        quantityUnit: string;
      };
      quantity: number;
    }[];
  }
};


export const MaterialsInfoFormSchema = Yup.object().shape({
  items: Yup.array().of(Yup.object().shape({
    material: Yup.object().shape({
      _id: Yup.string().required(),
      code: Yup.string().required(),
      name: Yup.string().required(),
      quantityUnit: Yup.string().required(),
    }).required(),
    quantity: Yup.number().min(0).required()
  }).required()).required().min(1, "Kindly enter at least one material"),
})

export const ResolveInspectionServiceOrderFormSchema = Yup.object().shape({
  materialsInfo: MaterialsInfoFormSchema.required()
})