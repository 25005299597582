import { TextInput } from "components/core"
import { ContractorPicker, MeterSystemPicker } from "containers";
import { FormikProps } from "formik"
import { FC } from "react"

interface MeterBrandFormProps {
  form: FormikProps<{
    code: string;
    name: string;
    contractor: string;
    system: string;
  }>;
}

const MeterBrandForm: FC<MeterBrandFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200 min-h-[40vh]">
      <div>
        <span className="text-xs font-light">Meter Brand Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">

          <div className="">
            <TextInput
              id="code"
              label="Brand Code"
              type="text"
              placeholder="e.g. MC000"
              {...form}
              required={true}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="name"
              label="Brand Name"
              type="text"
              placeholder="e.g. Wasion"
              {...form}
              required={true}
            />
          </div>
          
          <div className="col-span-2">
            <ContractorPicker
              id="contractor"
              label="Meter Manuafacturing Contractor"
              placeholder="e.g. Alpha"
              {...form}
              position={"bottom"}
              rawId={true}
            />
          </div>
          
          <div className="col-span-1">
            <MeterSystemPicker
              id="system"
              label="Meter System"
              placeholder="e.g. MMS"
              {...form}
              rawId={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeterBrandForm