import MetersInfoForm from "./meters-info-form";
import MetersInfoSummary from "./meters-info-summary";

const formSteps = [
  {
    name: "Meters Info",
    description: "Information about meters",
    accessor: "metersInfo",
    FormComponent: MetersInfoForm,
    SummaryComponent: MetersInfoSummary,
  },
];

export default formSteps;
