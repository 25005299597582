import { TextInput } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

interface MeterSystemFormProps {
  form: FormikProps<{
    code: string;
    name: string;
  }>;
}

const MeterSystemForm: FC<MeterSystemFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200 min-h-[40vh]">
      <div>
        <span className="text-xs font-light">Meter System Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">

          <div className="">
            <TextInput
              id="code"
              label="System Code"
              type="text"
              placeholder="e.g. MC000"
              {...form}
              required={true}
            />
          </div>

          <div className="col-span-2">
            <TextInput
              id="name"
              label="System Name"
              type="text"
              placeholder="e.g. Wasion"
              {...form}
              required={true}
            />
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default MeterSystemForm