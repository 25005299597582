import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface PlotPickerContainerProps {
  filter?: {
    region?: string;
    district?: string;
    block?: string;
    round?: string;
    plot?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any
  rawId?: boolean;
}

const GET_ITINERARIES = gql`
  query GetItinerariesSelect($region: ID $district: ID $block: ID $round: ID $plot: ID) {
    itineraries: getItineraries(region: $region district: $district block: $block round: $round plot: $plot sort: "code") {
      _id
      code
    }
  }
`;

const PlotPickerContainer: FC<PlotPickerContainerProps> = ({ filter, id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_ITINERARIES, {
    variables: {
      ...(filter ? filter : {})
    },
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "itinerary"}
      label={label ?? "Itinerary"}
      placeholder="Select Itinerary"
      optionsLoading={loading}
      options={(data?.itineraries ?? [])?.map((itinerary: any) => ({
        label: {
          title: itinerary.code as string
        },
        value: rawId ? itinerary._id : itinerary
      }))}
      {...form}
    />
  )
}

export default PlotPickerContainer