import { MeterBrandPicker, MeterModelPicker } from "containers";
import { FormikProps } from "formik"
import _ from "lodash";
import { FC } from "react"

interface MeterFormProps {
  form: FormikProps<{
    model: string;
    brand: string;
  }>;
  meter: any
}

const MeterForm: FC<MeterFormProps> = ({ form, meter }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200 min-h-[40vh]">
      <div className=''>
        <span className='text-xs font-light'>Meter Model Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Brand
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.modelMeta?.brandCode || "N/A"} - {meter?.modelMeta?.brandName || "N/A"}
            </div>
          </div>

          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              Model
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.modelMeta?.modelCode || "N/A"} - {meter?.modelMeta?.modelName || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              System
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.modelMeta?.systemCode || "N/A"} - {meter?.modelMeta?.systemName || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              Phase
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {meter?.modelMeta?.phase || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              Status
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {_.startCase(meter?.status) || "N/A"}
            </div>
          </div>

        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Meter Information</span>
        <div className="grid grid-cols-2 gap-6 mt-2">
          <div className="">
            <MeterBrandPicker
              id="brand"
              label="New Brand"
              placeholder="e.g. MC000"
              rawId
              {...form}
              required={true}
            />
          </div>
          <div className="">
            <MeterModelPicker
              id="model"
              label="New Model"
              placeholder="e.g. MC000"
              filter={{ brand: form.values.brand }}
              rawId
              {...form}
              required={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeterForm