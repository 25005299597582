import { useQuery, useReactiveVar } from "@apollo/client";
import { Shimmers, TableComponent } from "components";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, withPermissions, useTableData } from "utils";
import config from "config";
import CreateMeterTransferContainer from "./create";
import ViewMeterTransferContainer from "./view";
import { Avatar, SelectInput } from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import OfficeHeader from "components/layouts/office-header";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import numeral from "numeral";
import { GET_METER_TRANSFERS } from "apollo/queries";
import { currentUserVar } from "apollo/cache/auth";

const searchOptions = [
  {
    label: "Code",
    value: "code",
    min: 4,
  },
];
const MeterTransfersPage: FC<{
  type: "Incoming" | "Outgoing" | "Reversal";
}> = ({ type }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const currentUser = useReactiveVar(currentUserVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [orderStatus] = useUrlState("orderStatus");
  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      type,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: searchParams.orderStatus || undefined,
    }),
    [searchParams, type]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_METER_TRANSFERS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <OfficeHeader
        renderActions={
          type === "Outgoing"
            ? () => (
                <>
                  {withPermissions([
                    "*:*",
                    "meter-outgoing-requests:*",
                    "meter-outgoing-requests:create",
                  ])(
                    <button
                      type='button'
                      onClick={wrapClick(() => setModal("create"))}
                      className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                    >
                      <DocumentPlusIcon
                        className='-ml-0.5 mr-2 h-4 w-4'
                        aria-hidden='true'
                      />
                      Start New Transfer
                    </button>
                  )}
                </>
              )
            : currentUser.region && type === "Reversal"
            ? () => (
                <>
                  {withPermissions([
                    "*:*",
                    "meter-outgoing-requests:*",
                    "meter-outgoing-requests:create",
                  ])(
                    <button
                      type='button'
                      onClick={wrapClick(() => setModal("create"))}
                      className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                    >
                      <DocumentPlusIcon
                        className='-ml-0.5 mr-2 h-4 w-4'
                        aria-hidden='true'
                      />
                      Start New Reversal
                    </button>
                  )}
                </>
              )
            : undefined
        }
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"meter transfers"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )}
            renderHeaderItems={() => (
              <div className='mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2'></div>
            )}
            // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="MeterTransfer"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code | Date
                </th>
                {type === "Outgoing" && (
                  <>
                    {currentUser?.region ? (
                      <th
                        scope='col'
                        className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                      >
                        District
                      </th>
                    ) : (
                      <th
                        scope='col'
                        className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                      >
                        Region
                      </th>
                    )}
                  </>
                )}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Meter Brand
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Meter Model
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Start Serial Number
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  End Serial Number
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Quantity
                </th>
                {type === "Reversal" && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Origin
                  </th>
                )}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Created By
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.DoubleShimmer />
                </td>
                {type === "Outgoing" && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                {type === "Reversal" && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.AvatarShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                  <div className='font-medium text-gray-500 dark:text-gray-400'>
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                {type === "Outgoing" && (
                  <>
                    {currentUser?.region ? (
                      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                        <div className='font-medium text-gray-900 dark:text-gray-100'>
                          {item?.district?.code || "N/A"} -{" "}
                          {item?.district?.name || "N/A"}
                        </div>
                      </td>
                    ) : (
                      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                        <div className='font-medium text-gray-900 dark:text-gray-100'>
                          {item?.region?.code || "N/A"} -{" "}
                          {item?.region?.name || "N/A"}
                        </div>
                      </td>
                    )}
                  </>
                )}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.brand?.code || "N/A"} - {item?.brand?.name || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.model?.code || "N/A"} - {item?.model?.name || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.startSerialNumber || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.endSerialNumber || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {numeral(item?.quantity).format("0,0") || "N/A"}
                  </div>
                </td>
                {type === "Reversal" && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {(item?.type === "DistrictToCentral"
                        ? item?.district?.name
                        : item?.region?.name) || "N/A"}
                    </div>
                  </td>
                )}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.createdBy?.lastName || "")?.trim(),
                            (item?.createdBy?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.createdBy?.profileImageUrl || ""}
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {[
                          (item?.createdBy?.lastName || "")?.trim(),
                          (item?.createdBy?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.createdBy?.phoneNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {["Pending"].includes(orderStatus) && (
                      <ActionButton
                        action='cancel'
                        onClick={dispatchAction(item?._id, "cancel")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      <CreateMeterTransferContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={refetch}
        isReversal={type === "Reversal"}
      />
      {!!searchParams.id?.length && !!searchParams.modal?.length && (
        <>
          <ViewMeterTransferContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
            isReversal={type === "Reversal"}
            isCentralReversal={!currentUser.region && type === "Reversal"}
          />
        </>
      )}
    </main>
  );
};

export default MeterTransfersPage;
