import { SelectInput, TextArea } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

interface ProcessReplacementRetiredMeterFormProps {
    form: FormikProps<{
        notes: string;
        action: string;
    }>;
}

const ProcessReplacementRetiredMeterForm: FC<ProcessReplacementRetiredMeterFormProps> = ({ form }) => {

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className="text-xs font-light">Process Information</span>
                <div className="grid gap-6 mt-2">
                    <div>
                        <SelectInput
                            id="action"
                            label="Process Action"
                            placeholder="e.g. Details captured are conclusive"
                            required
                            options={[
                                { label: "--- Select ---", value: "" },
                                { label: "To Be Scrapped", value: "ToBeScrapped" },
                                { label: "To Be Refurbished", value: "ToBeRefurbished" },
                                { label: "To Be Reshipped", value: "ToBeReshipped" },
                            ]}
                            {...form}
                        />
                    </div>
                    <div>
                        <TextArea
                            id="notes"
                            label="Receipt Notes"
                            placeholder="e.g. Details captured are conclusive"
                            rows={10}
                            {...form}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ProcessReplacementRetiredMeterForm