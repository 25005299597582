import { FC, useEffect } from "react";
import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MeterModelForm } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_METER_MODEL } from "./view";
import { createMeterModelValidationSchema } from "components/forms/meter-model/schema";

const UPDATE_METER_MODEL = gql`
  mutation UpdateMeterModel(
    $id: ID!
    $digits: PositiveInt!
    $phase: String!
    $type: MeterModelType!
    $code: String
    $name: String
    $brand: ID
    $voltage: String
    $currentRating: String
    $mechanism: MeterModelMechanism
    $communicationType: MeterModelCommunicationType
  ) {
    updateMeterModel(
      id: $id
      digits: $digits
      phase: $phase
      type: $type
      code: $code
      name: $name
      brand: $brand
      voltage: $voltage
      currentRating: $currentRating
      mechanism: $mechanism
      communicationType: $communicationType
    ) {
      _id
    }
  }
`;

interface IUpdateMeterModelContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const UpdateMeterModelContainer: FC<IUpdateMeterModelContainerProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_METER_MODEL, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateMeterModel, { loading }] = useMutation(UPDATE_METER_MODEL);

  const form = useFormik({
    initialValues: {
      code: "",
      name: "",
      brand: "",
      voltage: "",
      currentRating: "",
      mechanism: "",
      communicationType: "",
      digits: 6,
      phase: "1 phase",
      type: "Prepaid" as "Prepaid" | "Postpaid" | "AMR",
    },
    validationSchema: createMeterModelValidationSchema,
    onSubmit: async (values) => {
      await updateMeterModel({
        variables: {
          id: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.updateMeterModel._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Meter Model Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Meter Model",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    form.setValues({
      code: data?.meterModel?.code || "",
      name: data?.meterModel?.name || "",
      brand: data?.meterModel?.brand?._id || "",
      voltage: data?.meterModel?.voltage || "",
      currentRating: data?.meterModel?.currentRating || "",
      mechanism: data?.meterModel?.mechanism || "",
      communicationType: data?.meterModel?.communicationType || "",
      digits: data?.meterModel?.digits || 6,
      phase: data?.meterModel?.phase || "1 phase",
      type: data?.meterModel?.type || "Prepaid",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.meterModel, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Meter Model Information"
      description="Provide the details to update meter model"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing Meter Model..." : "Edit Meter Model"}
          </button>
        </>
      )}
    >
      {data?.meterModel?._id && <MeterModelForm form={form} />}
    </Modal>
  );
};

export default UpdateMeterModelContainer;
