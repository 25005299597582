import MaterialsInfoForm from "./materials-info-form";
import MaterialsInfoSummary from "./materials-info-summary";

const formSteps = [
  {
    name: "Materials Info",
    description: "Information about materials",
    accessor: "materialsInfo",
    FormComponent: MaterialsInfoForm,
    SummaryComponent: MaterialsInfoSummary,
  },
]

export default formSteps;
