import * as Yup from "yup";

// export interface IMeterShipmentFormSchema {
//   metersInfo: {
//     brand: {
//       _id: string;
//       code: string;
//       name: string;
//     };
//     model: {
//       _id: string;
//       code: string;
//       name: string;
//     };
//     startSerialNumber: string;
//     endSerialNumber: string;
//     description: string;
//     code: string;
//     quantity: number;
//   };
// }

// export const MetersInfoFormSchema = Yup.object().shape({
//   brand: Yup.object().nullable().required("Required"),
//   model: Yup.object().nullable().required("Required"),
//   startSerialNumber: Yup.string().required("Required"),
//   description: Yup.string().required("Required"),
//   code: Yup.string().required("Required"),
//   endSerialNumber: Yup.string().required("Required"),
//   quantity: Yup.number().min(0).required("Required"),
// });

export interface IMeterShipmentFormSchema {
  metersInfo: {
    brand: {
      _id: string;
      code: string;
      name: string;
    };
    model: {
      _id: string;
      code: string;
      name: string;
    };
    serialNumbers: string[];
    description: string;
    code: string;
  };
}

export const MetersInfoFormSchema = Yup.object().shape({
  brand: Yup.object().nullable().required("Meter brand is required"),
  model: Yup.object().nullable().required("Meter model is required"),
  serialNumbers: Yup.array().of(Yup.string()).required("Kindly enter the shipment meter serial numbers").min(1, "Kindly enter at least one serial number"),
  description: Yup.string().required("Meter shipment description is required"),
  code: Yup.string().required("Meter shipment code is required"),
});

export const MeterShipmentFormSchema = Yup.object().shape({
  metersInfo: MetersInfoFormSchema.required(),
});
