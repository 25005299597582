import { FC, useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, MeterForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import * as yup from 'yup';

const GET_METER = gql`
query GetMeter($id: ID!) {
  meterToUpdated: getMeter(id: $id) {
    _id
    code
    status
    region {
      _id
      code
      name
    }
    district {
      _id
      code
      name
    }
    modelMeta {
      modelCode
      modelName
      phase
      systemCode
      systemName
      brandCode
      brandName
    }
    brand {
      _id
    }
    model {
      _id
    }
  }
}
`;

const UPDATE_METER_SYSTEM = gql`
mutation UpdateMeter($updateMeterId: ID!, $model: ID!) {
  updateMeter(id: $updateMeterId, model: $model) {
    _id
  }
}
`

interface IUpdateMeterSystemContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const UpdateMeterContainer: FC<IUpdateMeterSystemContainerProps> = ({ open, setOpen, refetch }) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading: fetching } = useQuery(GET_METER, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const [updateMeter, { loading }] = useMutation(UPDATE_METER_SYSTEM)

  const form = useFormik({
    initialValues: {
      brand: "",
      model: "",
    },
    validationSchema: yup.object({
      brand: yup.string().required("Please choose a meter brand"),
      model: yup.string().required("Please choose a meter model"),
    }),
    onSubmit: async (values) => {
      await updateMeter({
        variables: {
          updateMeterId: searchParams.id,
          model: values.model,
        }
      }).then(({ data }) => {
        if (data.updateMeter._id) {
          toast(JSON.stringify({ type: "success", title: "Meter System Edited Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Meter System" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      brand: data?.meterToUpdated?.brand?._id || "",
      model: data?.meterToUpdated?.model?._id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.meterToUpdated, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetching}
      title="Edit Meter Model"
      description="Provide the details to update meter model."
      size='2xl'
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing Model..." : "Edit Meter Model"}
          </button>

        </>
      )}>
      {data?.meterToUpdated?._id && (
        <MeterForm
          form={form}
          meter={data.meterToUpdated}
        />
      )}
    </Modal>
  )
}

export default UpdateMeterContainer;