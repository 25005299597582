import { withPermissions, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { MeterModelView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

export const GET_METER_MODEL = gql`
  query GetMeterModel($id: ID!) {
    meterModel: getMeterModel(id: $id) {
      _id
      code
      digits
      type
      phase
      createdAt
      updatedAt
      brand {
        _id
        code
        name
      }
      system {
        _id
        code
        name
      }
      contractor {
        _id
        code
        name
      }
      communicationType
      currentRating
      mechanism
      name
      voltage
    }
  }
`;

export default function ViewMeterModelContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_METER_MODEL, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update",
      }),
    });
  };

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      title="Meter Model Information"
      description="Details of meter model are shown below"
      renderActions={() => (
        <>
          {withPermissions(["*:*", "meter-models:*", "meter-models:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      <MeterModelView meterModel={data?.meterModel} />
    </Modal>
  );
}
