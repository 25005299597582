import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import moment from 'moment';
import numeral from 'numeral';
import { FC } from 'react'

interface MaterialRequestViewProps {
  displayRequester?: boolean;
  materialRequest: {
    _id: string;
    code: string;
    type: string;
    items: {
      material: {
        _id: string;
        code: string;
        name: string;
        quantityUnit: string;
      };
      quantity: number;
    }[];
    region: {
      _id: string;
      code: string;
      name: string;
    };
    district: {
      _id: string;
      code: string;
      name: string;
    };
    createdBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    approvedBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    cancelledBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    rejectedBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    status: string;
    rejectedAt: string;
    rejectedBecause: string;
    cancelledAt: string;
    cancelledBecause: string;
    approvedAt: string;
    approvalNotes: string;
    createdAt: string;
    updatedAt: string;
  }
}

const MaterialRequestView: FC<MaterialRequestViewProps> = ({ materialRequest, displayRequester }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className=''>
        <div className=''>
          <span className="text-xs font-light">Request Information</span>
          <div className='grid grid-cols-3 gap-6 mt-2'>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {materialRequest?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Created At
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {moment(materialRequest?.createdAt).format(dateFormat + ", hh:mm A")}
              </div>
            </div>
            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Created By
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {[(materialRequest?.createdBy?.lastName || "")?.trim(), (materialRequest?.createdBy?.firstName || "")?.trim()].join(" ").trim() || "N A"}
              </div>
            </div>
            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Status
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {materialRequest?.status || "N/A"}
              </div>
            </div>
            {displayRequester && (materialRequest.type === "DistrictToRegion" ? (
              <>
              <div className="col-span-1">
                <span className="block text-sm font-light text-gray-700">
                  District Code
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {materialRequest?.district?.code || "N/A"}
                </div>
              </div>
            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                District Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {materialRequest?.district?.name || "N/A"}
              </div>
            </div>
            </>
            ):(
              <div className="col-span-1">
                <span className="block text-sm font-light text-gray-700">
                  Status
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {materialRequest?.status || "N/A"}
                </div>
              </div>
              )
            )}
            {materialRequest?.status === "Approved" && (
              <>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Approved At
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(materialRequest?.approvedAt).format(dateFormat + ", hh:mm A")}
                </div>
              </div>
              <div className="col-span-1">
                <span className="block text-sm font-light text-gray-700">
                  Approved By
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {[(materialRequest?.approvedBy?.lastName || "")?.trim(), (materialRequest?.approvedBy?.firstName || "")?.trim()].join(" ").trim() || "N A"}
                </div>
              </div>
              <div className="col-span-3">
                <span className="block text-sm font-light text-gray-700">
                Approval Notes
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                {materialRequest?.approvalNotes || "N/A"}
                </div>
              </div>
              </>
            )}
            {materialRequest?.status === "Rejected" && (
              <>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Rejected At
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(materialRequest?.rejectedAt).format(dateFormat + ", hh:mm A")}
                </div>
              </div>
              <div className="col-span-1">
                <span className="block text-sm font-light text-gray-700">
                Rejected By
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {[(materialRequest?.rejectedBy?.lastName || "")?.trim(), (materialRequest?.rejectedBy?.firstName || "")?.trim()].join(" ").trim() || "N A"}
                </div>
              </div>
              <div className="col-span-3">
                <span className="block text-sm font-light text-gray-700">
                Rejected Because
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                {materialRequest?.rejectedBecause || "N/A"}
                </div>
              </div>
              </>
            )}
            {materialRequest?.status === "Cancelled" && (
              <>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Cancelled At
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(materialRequest?.approvedAt).format(dateFormat + ", hh:mm A")}
                </div>
              </div>
              <div className="col-span-1">
                <span className="block text-sm font-light text-gray-700">
                  Cancelled By
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {[(materialRequest?.approvedBy?.lastName || "")?.trim(), (materialRequest?.approvedBy?.firstName || "")?.trim()].join(" ").trim() || "N A"}
                </div>
              </div>
              <div className="col-span-3">
                <span className="block text-sm font-light text-gray-700">
                Cancelled Because
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                {materialRequest?.cancelledBecause || "N/A"}
                </div>
              </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <div className=''>
          <span className="text-xs font-light">Meterials Information</span>
          <div className='grid grid-cols-3 gap-6 mt-2'>
            <div className="col-start-1 col-span-3">
              <span className="block text-sm font-light text-gray-700">
                Materials
              </span>
              <div className="mt-1 w-full overflow-hidden">
                <table className="min-w-full table-fixed divide-y divide-gray-300 border border-gray-300 rounded-md">
                  <thead className="bg-gray-50">
                    <tr className="divide-x divide-gray-200">
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[70%]"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right"
                      >
                        Unit
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {materialRequest?.items?.map((material, idx) => (
                      <tr key={idx} className="divide-x divide-gray-200">
                        <td className="whitespace- px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden ">{material.material?.name || "N/A"}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(material?.quantity || 0).format("0,0")}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{material?.material.quantityUnit || "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaterialRequestView





