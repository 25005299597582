import { wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { ServiceRegularizationView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

const GET_SERVICE_REQUEST = gql`
  query GetRegularization($id: ID!) {
    serviceRegularization: getRegularization(id: $id) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      accountCode
      customerCode
      servicePointCode
      customerData {
        customerType

        organization {
          name
          taxIdentificationNumber
          organizationRegistrationNumber
          organizationRegistrationDate
          organizationRegistrationDocumentUrl
          certificateOfIncorporationDocumentUrl
        }
        representative {
          title
          fullName
          nationality
          dateOfBirth
          gender
          phoneNumber
          emailAddress
          profileImageUrl
          hasGhanaCard
          ghanaCardNumber
          ghanaCardIssueDate
          ghanaCardExpiryDate
          ghanaCardFrontImageUrl
          ghanaCardBackImageUrl
          identityCardType
          identityCardNumber
          identityCardIssueDate
          identityCardExpiryDate
          identityCardFrontImageUrl
          identityCardBackImageUrl
        }
      }
      property {
        ghanaPostAddress
        community
        streetName
        houseNumber
        structureNumber
        landmark
        sitePlanDocumentUrl
        geoLocation {
          type
          coordinates
        }
        owner {
          fullName
          phoneNumber
        }
        premiseCategory {
          _id
          code
          name
        }
        premiseType {
          _id
          code
          name
        }
        activity {
          _id
          code
          name
        }
        subActivity {
          _id
          code
          name
        }
      }
      service {
        serviceType
        serviceClass
        meterPhase
        energyCertificateNumber
        energyCertificateDocumentUrl
      }
      billing {
        neighbourhoodClass
        numberOfRooms
        numberOfMonths
        hasMeter
        meterNumber
        meterImageUrl
      }
      createdAt
      updatedAt
    }
  }
`;

export default function ViewServiceRegularizationContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const expandItem = () => {
    navigate({
      to: `./${searchParams.id}`,
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title='Service Regularization Information'
      description='Details of service regularization are shown below'
      size='4xl'
      renderActions={() => (
        <>
          {false && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(expandItem)}
            >
              View More
            </button>
          )}
        </>
      )}
    >
      <ServiceRegularizationView
        serviceRegularization={data?.serviceRegularization}
      />
    </Modal>
  );
}
