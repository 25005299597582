import { wrapClick } from 'utils';
import { gql, useMutation, } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, MaterialShipmentForm } from 'components';

const CREATE_MATERIAL_SHIPMENT = gql`
  mutation CreateMaterialShipment(
    $code: String!
    $description: String!
    $items: [MaterialShipmentItemInput!]!
  ) {
    createMaterialShipment(
      code: $code
      description: $description
      items: $items
    ) {
      _id
    }
  }
`

export default function CreateMaterialShipmentContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
       
  const [createMaterialShipment, { loading }] = useMutation(CREATE_MATERIAL_SHIPMENT)

  const form = useFormik({
    initialValues: {
      materialsInfo: {
        code: "",
        description: "",
        items: [] as {
          material: any;
          quantity: number;
        }[]
      },
    },
    onSubmit: async (values) => {
      await createMaterialShipment({
        variables: {
          ...values.materialsInfo,
          items: values.materialsInfo.items.map((item) => ({
            material: item.material._id,
            quantity: item.quantity
          }))
        }
      }).then(({ data }) => {
        if (data.createMaterialShipment._id) {
          toast(JSON.stringify({ type: "success", title: "Shipment Created Successfully" }));
          refetch?.();
          form.resetForm();
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Shipment" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Material Shipment"
      description="Provide the details to add a new shipment"
      hideActions
      hidePadding
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Shipment..." : "Add Shipment"}
          </button>

        </>
      )}>
      <MaterialShipmentForm
        form={form}
      />
    </Modal>
  )
}