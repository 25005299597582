import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface MeterPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  rawId?: boolean;
}

const GET_REGIONS = gql`
  query GetMetersSelect {
    meters: getMeters(sort: "name" page: 0 pageSize: 0) {
      _id
      code
      model {
        make
        model
        digits
      }
    }
  }
`;

const MeterPickerContainer: FC<MeterPickerContainerProps> = ({ id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_REGIONS, {
    notifyOnNetworkStatusChange: false,
  })

  return (
    <SearchSelectInput
      id={id ?? "meter"}
      label={label ?? "Meter"}
      placeholder="Select Meter"
      optionsLoading={loading}
      options={(data?.meters ?? [])?.map((meter: any) => ({
        label: {
          title: meter.code as string,
        },
        value: rawId ? meter?._id : meter
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default MeterPickerContainer