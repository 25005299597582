import { wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, MeterShipmentForm } from "components";

// const CREATE_METER_SHIPMENT = gql`
//   mutation CreateMeterShipment(
//     $code: String!
//     $description: String!
//     $model: ID!
//     $startSerialNumber: String!
//     $endSerialNumber: String!
//     $quantity: PositiveInt!
//   ) {
//     createMeterShipment(
//       code: $code
//       description: $description
//       model: $model
//       startSerialNumber: $startSerialNumber
//       endSerialNumber: $endSerialNumber
//       quantity: $quantity
//     ) {
//       _id
//     }
//   }
// `;

const CREATE_METER_SHIPMENT = gql`
  mutation CreateMeterShipment(
    $code: String!
    $description: String!
    $model: ID!
    $serialNumbers: [String!]!
  ) {
    createMeterShipment(
      code: $code
      description: $description
      model: $model
      serialNumbers: $serialNumbers
    ) {
      _id
    }
  }
`;

export default function CreateMeterShipmentContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createMeterShipment, { loading }] = useMutation(CREATE_METER_SHIPMENT);

  const form = useFormik({
    initialValues: {
      metersInfo: {
        brand: null as any,
        model: null as any,
        code: "",
        description: "",
        serialNumbers: [] as string[],
      },
    },
    onSubmit: async (values) => {
      await createMeterShipment({
        variables: {
          ...values.metersInfo,
          model: values.metersInfo.model._id,
        },
      }).then(({ data }) => {
        if (data.createMeterShipment._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Shipment Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Shipment",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New Meter Shipment"
      description="Provide the details to add a new shipment"
      hideActions
      hidePadding
      size="5xl"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding Shipment..." : "Add Shipment"}
          </button>
        </>
      )}
    >
      <MeterShipmentForm form={form} />
    </Modal>
  );
}
