import { withPermissions, wrapClick } from 'utils';
import { gql, useQuery } from '@apollo/client';
import { MeterSystemView, Modal } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';

const GET_METER_SYSTEM = gql`
  query GetMeterSystem($id: ID!) {
    meterSystem: getMeterSystem(id: $id) {
      _id
      code
      name
      createdAt
      updatedAt
    }
  }
`

export default function ViewMeterSystemContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data } = useQuery(GET_METER_SYSTEM, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const updateItem = () => {
    navigate({
      search: (old) => ({
        ...old,
        modal: "update"
      })
    })
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Meter System Information"
      description="Details of meter model are shown below"
      hidePadding
      size='2xl'
      renderActions={() => (
        <>
          {withPermissions(["*:*", "meter-models:*", "meter-models:update"])(
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(updateItem)}
            >
              Edit
            </button>
          )}
        </>
      )}
    >
      <div className='p-6'>
        <MeterSystemView
          meterSystem={data?.meterSystem}
        />
      </div>
    </Modal>
  )
}