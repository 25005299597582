import { SelectInput, TextInput } from "components/core"
import { FormikProps } from "formik"
import { FC } from "react"

interface MaterialFormProps {
  form: FormikProps<{
    code: string;
    name: string;
    category: string;
    quantityUnit: string;
  }>;
}

const MaterialForm: FC<MaterialFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Material Information</span>
        <div className="grid grid-cols-6 gap-6 mt-2">

          <div className="col-span-2">
            <TextInput
              id="code"
              label="Code"
              type="text"
              placeholder="e.g. MA1234"
              {...form}
            />
          </div>

          <div className="col-span-4">
            <TextInput
              id="name"
              label="Name"
              type="text"
              placeholder="e.g. Cables"
              {...form}
            />
          </div>

          <div className="col-span-3">
            <SelectInput
              id="category"
              label="Category"
              placeholder="e.g. Prepaid"
              {...form}
              options={[
                { label: "--- Select Meter Type ---", value: "" },
                { label: "CategoryA", value: "CategoryA" },
              ]}
            />
          </div>
          <div className="col-span-3">
            <SelectInput
              id="quantityUnit"
              label="Unit"
              placeholder="e.g. km"
              {...form}
              options={[
                { label: "--- Select Unit ---", value: "" },
                { label: "km", value: "km" },
                { label: "ea", value: "ea" },
                { label: "roll", value: "roll" },
                { label: "set", value: "set" },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaterialForm