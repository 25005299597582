import { gql, useQuery } from "@apollo/client";

const GET_REMOVED_METERS = gql`
query GetReplacementServiceOrders(
  $page: Int
  $pageSize: Int
  $search: String
  $searchFields: [String!]
  $region: ID
  $district: ID
  $sort: String
  $retiredMeterStatus: ReplacementRetiredMeterStatus
) {
  count: getReplacementServiceOrdersCount(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    retiredMeterStatus: $retiredMeterStatus
  )
  rows: getReplacementServiceOrders(
    page: $page
    pageSize: $pageSize
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    sort: $sort
    retiredMeterStatus: $retiredMeterStatus
  ) {
    _id
    code
    category
    assigneeType
    replacementType
    replacementReason
    oldMeterNumber
    oldMeterPhase
    servicePointCode
    accountCode
    status
    debtStatus
    priority
    region {
      name
    }
    district {
      name
    }
    meter {
      _id
      code
      batchCode
      modelType
      modelMeta {
        brandCode
        brandName
        modelCode
        modelName
        phase
      }
    }
    completedAt
    retiredMeterStatus
  }
}
`;

const GET_REMOVED_METERS_SUMMARY = gql`
query GetReplacementServiceOrderRetiredMeterSummary(
  $region: ID
  $district: ID
  $meterContractor: ID
) {
  getReplacementServiceOrderRetiredMeterSummary(
    region: $region
    district: $district
    meterContractor: $meterContractor
  ) {
    PendingReceipt
    PendingProcessing
    ToBeScrapped
    Scrapped
    ToBeRefurbished
    Refurbished
    ToBeReshipped
    Reshipped
  }
}
`;


export const useRemovedMeters = (variables?: any, summaryVariables?: any) => {
  const { data: dataSummary } = useQuery(GET_REMOVED_METERS_SUMMARY, {
    variables: summaryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  })
  const { data, ...rest } = useQuery(GET_REMOVED_METERS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  })
  return {
    removedMeters: data?.rows || [],
    summary: dataSummary?.getReplacementServiceOrderRetiredMeterSummary || {},
    count: data?.count || 0,
    ...rest
  };
}