import * as Yup from 'yup';

export const SigninSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
})

export const SigninInitialValues = {
  email: "",
  password: "",
}

export const createMaterialValidationSchema = Yup.object({
  code: Yup.string().matches(/^MA\d{4}$/, "Kindly enter a valid material code").required("Kindly enter the material code"),
  name: Yup.string().required("Kindly enter the material name").min(2, "Material name is too short").max(512, "Material name is too long"),
  category: Yup.string(),
  quantityUnit: Yup.string().required("Kindly choose the material's unit"),
})