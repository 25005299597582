import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { FC, useMemo } from "react";
import { useNavigate, useRouter } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, wrapClick } from "utils";

interface HeaderProps {
  renderActions?: FC<{}>;
}

const Header: FC<HeaderProps> = ({ renderActions }) => {
  const { matches } = useRouter<LocationGenerics>().state;
  const navigate = useNavigate();
  const breadcrumbs = useMemo(
    () => matches.filter((match) => match?.route?.meta?.breadcrumb),
    [matches]
  );
  const firstBreadcrumb = _.first(breadcrumbs);
  const lastBreadcrumb = _.last(breadcrumbs);

  const openLink = (link: string) => () => {
    navigate({
      to: link,
    });
  };

  const goBack = () => {
    const destination = _.nth(breadcrumbs, -2);
    if (destination?.pathname !== lastBreadcrumb?.pathname) {
      navigate({
        to: destination?.pathname,
        replace: true,
      });
    }
  };

  return (
    <div className="p-4 flex items-center justify-between flex-shrink-0 bg-white shadow-sm">
      <div className="min-w-0 flex-1">
        <div className="flex flex-row  items-center space-x-2">
          {breadcrumbs.length > 1 && (
            <button
              type="button"
              onClick={wrapClick(goBack)}
              className="inline-flex items-center rounded border border-transparent p-1 text-gray-700 bg-gray-50 shadow-sm hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              <ArrowLeftIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          )}
          <h2 className="text-lg leading-7 text-gray-900 tracking-wider">
            {lastBreadcrumb?.route.meta?.breadcrumb?.(lastBreadcrumb.params)}
          </h2>
        </div>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-2">
            <li>
              <div className="flex">
                <span className="text-xs font-medium text-gray-500">
                  {firstBreadcrumb?.route.meta?.section}
                </span>
              </div>
            </li>
            {breadcrumbs.map((match) => (
              <li key={match.pathname}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <button
                    onClick={wrapClick(openLink(match.pathname))}
                    disabled={lastBreadcrumb?.id === match.id}
                    className={classNames(
                      lastBreadcrumb?.id === match.id
                        ? "bg-gray-100"
                        : "bg-white",
                      "ml-2 text-xs font-medium text-gray-500 rounded-md p-0.5 px-1.5 hover:bg-gray-200 hover:text-gray-700 "
                    )}
                    aria-current={
                      lastBreadcrumb?.id === match.id ? "page" : undefined
                    }
                  >
                    {match.route.meta?.breadcrumb(match.params)}
                  </button>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      {_.isFunction(renderActions) && (
        <div className="mt-4 flex flex-shrink-0 md:mt-0 md:ml-4 space-x-3">
          {renderActions({})}
        </div>
      )}
    </div>
  );
};

export default Header;
