import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import lodash from "lodash";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";

interface MeterShipmentViewProps {
  meterShipment: {
    assignments: any;
    status: string;
    district: any;
    contractor: any;
    meta: any;
    brand: any;
    model: any;
    startSerialNumber: string;
    quantity: number;
    endSerialNumber: string;
    serialNumbers: string[];
    _id: string;
    code: string;
    description: string;
    createdBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    createdAt: string;
    updatedAt: string;
  };
}

const MeterShipmentView: FC<MeterShipmentViewProps> = ({ meterShipment }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Shipment Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterShipment?.code || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                District
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterShipment?.district?.name || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Contractor
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterShipment?.contractor?.name || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Created At
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {moment(meterShipment?.createdAt).format(
                  dateFormat + ", hh:mm A"
                )}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Created By
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {[
                  (meterShipment?.createdBy?.lastName || "")?.trim(),
                  (meterShipment?.createdBy?.firstName || "")?.trim(),
                ]
                  .join(" ")
                  .trim() || "N A"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Meters Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-start-1">
              <span className="block text-sm font-light text-gray-700">
                Start Serial Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {lodash.chain(meterShipment?.assignments).map("mfgSerialNumber").sort().first().value() || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                End Serial Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
              {lodash.chain(meterShipment?.assignments).map("mfgSerialNumber").sort().last().value() || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Status
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterShipment?.status || "N/A"}
              </div>
            </div>
            <div className="col-start-1">
              <span className="block text-sm font-light text-gray-700">
                Total Meters
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {numeral(meterShipment?.meta?.totalAssignments).format("0,0") || "N/A"} Meters
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Total Successful
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {numeral(meterShipment?.meta?.totalSuccessAssignments).format("0,0") || "N/A"} Meters
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Total Failed
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {numeral(meterShipment?.meta?.totalFailedAssignments).format("0,0") || "N/A"} Meters
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterShipmentView;
