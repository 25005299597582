import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";

interface MeterRequestViewProps {
  isCentralReversal: boolean;
  displayRequester?: boolean;
  meterTransfer: {
    meta: any;
    _id: string;
    code: string;
    type: string;

    brand: {
      _id: string;
      code: string;
      name: string;
    };
    system: {
      _id: string;
      code: string;
      name: string;
    };
    model: {
      _id: string;
      code: string;
      name: string;
      type: string;
      phase: string;
      voltage: string;
      currentRating: string;
    };
    startSerialNumber: string;
    endSerialNumber: string;
    serialNumbers: string[];
    quantity: number;
    region: {
      _id: string;
      code: string;
      name: string;
    };
    district: {
      _id: string;
      code: string;
      name: string;
    };
    createdBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    notes: string;
    createdAt: string;
    updatedAt: string;
  };
}

const MeterRequestView: FC<MeterRequestViewProps> = ({
  meterTransfer,
  displayRequester,
  isCentralReversal
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Transfer Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterTransfer?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Created At
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {moment(meterTransfer?.createdAt).format(
                  dateFormat + ", hh:mm A"
                )}
              </div>
            </div>
            <div className="col-span-1">
              <span className="block text-sm font-light text-gray-700">
                Created By
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {[
                  (meterTransfer?.createdBy?.lastName || "")?.trim(),
                  (meterTransfer?.createdBy?.firstName || "")?.trim(),
                ]
                  .join(" ")
                  .trim() || "N A"}
              </div>
            </div>
            {["RegionToDistrict", "DistrictToCentral"].includes(meterTransfer?.type)  ? (
              <>
                <div className="col-span-1">
                  <span className="block text-sm font-light text-gray-700">
                    District Code
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {meterTransfer?.district?.code || "N/A"}
                  </div>
                </div>
                <div className="col-span-1">
                  <span className="block text-sm font-light text-gray-700">
                    District Name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {meterTransfer?.district?.name || "N/A"}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-span-1">
                  <span className="block text-sm font-light text-gray-700">
                    Region Code
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {meterTransfer?.region?.code || "N/A"}
                  </div>
                </div>
                <div className="col-span-1">
                  <span className="block text-sm font-light text-gray-700">
                    Region Name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {meterTransfer?.region?.name || "N/A"}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Meter Model Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Meter Brand
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterTransfer?.brand?.name || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Meter Model
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterTransfer?.model?.name || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Meter System
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterTransfer?.system?.name || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Meter Phase
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterTransfer?.model?.phase || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Meter Voltage
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterTransfer?.model?.voltage || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                Meter Current Rating
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterTransfer?.model?.currentRating || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-6">
        <div className="">
          <span className="text-xs font-light">Serial Numbers Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Start Serial Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterTransfer?.startSerialNumber || "N/A"}
              </div>
            </div>

            <div>
              <span className="block text-sm font-light text-gray-700">
                End Serial Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {meterTransfer?.endSerialNumber || "N/A"}
              </div>
            </div>
            {isCentralReversal ? (
              <div className="">
                <span className="block text-sm font-light text-gray-700">
                  Total Meters
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(meterTransfer?.meta?.totalTransfers).format("0,0") ||
                    "N/A"}{" "}
                  Meters
                </div>
              </div>
            ) : (
              <>
                <div className="col-start-1">
                  <span className="block text-sm font-light text-gray-700">
                    Total Meters
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {numeral(meterTransfer?.meta?.totalTransfers).format(
                      "0,0"
                    ) || "N/A"}{" "}
                    Meters
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Total Successful
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {numeral(meterTransfer?.meta?.totalSuccessTransfers).format(
                      "0,0"
                    ) || "N/A"}{" "}
                    Meters
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Total Failed
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {numeral(meterTransfer?.meta?.totalFailedTransfers).format(
                      "0,0"
                    ) || "N/A"}{" "}
                    Meters
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterRequestView;
