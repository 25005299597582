import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Shimmers, TableComponent } from "components";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, classNames, useTableData } from "utils";
import config from "config";
import CreateMeterShipmentContainer from "./create";
import ViewMeterShipmentContainer from "./view";
import { Avatar, SelectInput } from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import OfficeHeader from "components/layouts/office-header";
import numeral from "numeral";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import ViewOrderMeterShipmentContainer from "./view-order";

const searchOptions = [
  {
    label: "Description",
    value: "description",
    min: 5,
  },
];

const GET_METER_SHIPMENTS = gql`
  query GetMeterShipments(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $status: MeterShipmentStatus
    $type: MeterShipmentType
    $searchFields: [String!]
  ) {
    rows: getMeterShipments(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      status: $status
      type: $type
    ) {
      _id
      code
      description
      createdBy {
        _id
        code
        emailAddress
        firstName
        gender
        ghanaCardNumber
        lastName
        phoneNumber
        profileImageUrl
      }
      createdAt
      updatedAt
      endSerialNumber
      quantity
      startSerialNumber
      model {
        name
        type
      }
      status
      contractor {
        _id
        code
        name
      }
      contractorCreatedBy {
        _id
        code
        emailAddress
        firstName
        gender
        lastName
        phoneNumber
        profileImageUrl
      }
      type
      shipments {
        meterSystemDetails {
          manufacturer
        }
      }
    }
    count: getMeterShipmentsCount(
      search: $search
      searchFields: $searchFields
      status: $status
      type: $type
    )
  }
`;

const GET_CONTRACTOR_INSTALLATION_SHIPMENTS = gql`
  query GetInstallationServiceOrderAssignments(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $region: ID
    $district: ID
    $contractor: ID
    $status: InstallationServiceOrderAssignmentStatus
  ) {
    rows: getInstallationServiceOrderAssignments(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      region: $region
      district: $district
      contractor: $contractor
      status: $status
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      status
      meta {
        totalSuccessAssignments
        totalFailedAssignments
        totalAssignments
      }
      createdBy {
        _id
        code
        lastName
        firstName
        gender
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      contractor {
        _id
        code
        name
        address
        ghanaPostAddress
        contactPerson {
          fullName
          emailAddress
          phoneNumber
        }
      }
      createdAt
      updatedAt
    }
    count: getInstallationServiceOrderAssignmentsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      contractor: $contractor
      status: $status
    )
  }
`;

const GET_CONTRACTOR_REPLACEMENT_SHIPMENTS = gql`
  query GetReplacementServiceOrderAssignments(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $region: ID
    $district: ID
    $contractor: ID
    $status: ReplacementServiceOrderAssignmentStatus
  ) {
    rows: getReplacementServiceOrderAssignments(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      region: $region
      district: $district
      contractor: $contractor
      status: $status
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      status
      meta {
        totalSuccessAssignments
        totalFailedAssignments
        totalAssignments
      }
      createdBy {
        _id
        code
        lastName
        firstName
        gender
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      contractor {
        _id
        code
        name
        address
        ghanaPostAddress
        contactPerson {
          fullName
          emailAddress
          phoneNumber
        }
      }
      createdAt
      updatedAt
    }
    count: getReplacementServiceOrderAssignmentsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      contractor: $contractor
      status: $status
    )
  }
`;

const sections = [
  { name: "Central Store Shipments", href: "InternalShipment" },
];

const altSections = [
  { name: "Meter Shipments", href: "ExternalShipment" },
  { name: "Installation Shipments", href: "InstallationShipment" },
  { name: "Replacement Shipments", href: "ReplacementShipment" },
];

const MeterShipmentsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const [searchBy, setSearchParameter] = useUrlState("search_field");
  const [searchValue, setSearch] = useUrlState("search");
  const [minSearch, setMinSearch] = useUrlState("minSearch");

  const updateSearchBy = (key: string) => {
    setSearchParameter(key);
    if (key) {
      setMinSearch(searchOptions.find((option) => option.value === key)?.min);
    }
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search:
        searchParams.search && searchBy
          ? (searchParams.search || "").toString()
          : undefined,
      searchFields: searchBy && searchValue ? [searchBy] : undefined,
      sort: searchParams.sort || "",
      status: undefined,
      ...(searchParams?.section === "InternalShipment"
        ? { type: "Internal" }
        : {}),
      ...(searchParams?.section === "ExternalShipment"
        ? { type: "External" }
        : {}),
    }),
    [searchParams]
  );

  const QUERY = useMemo(() => {
    switch (searchParams?.section) {
      case "InstallationShipment": {
        return GET_CONTRACTOR_INSTALLATION_SHIPMENTS;
      }
      case "ReplacementShipment": {
        return GET_CONTRACTOR_REPLACEMENT_SHIPMENTS;
      }
      default: {
        return GET_METER_SHIPMENTS;
      }
    }
  }, [searchParams?.section]);

  const { data, loading, networkStatus, refetch } = useQuery(QUERY, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
        ...(!old?.section ? { section: "InternalShipment" } : {}),
      }),
    });
  }, [navigate]);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        page: config.constants.page,
        pageSize: config.constants.pageSize,
        search: "",
      }),
    });
  }, [searchParams.section, navigate]);

  const records = useTableData(data || {});

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <OfficeHeader
        renderActions={() => (
          <>
            {/* {withPermissions(["*:*", "meter-models:*", "meter-models:create"])( */}
            <button
              type='button'
              onClick={wrapClick(() => setModal("create"))}
              className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
            >
              <DocumentPlusIcon
                className='-ml-0.5 mr-2 h-4 w-4'
                aria-hidden='true'
              />
              Add New Shipment
            </button>
            {/* )} */}
          </>
        )}
      />
      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav className='-mb-px flex space-x-8 items-center' aria-label='Tabs'>
            {sections.map((_section) => (
              <Link
                key={_section.name}
                search={(old) => ({
                  ...old,
                  section: _section.href,
                })}
                className={classNames(
                  searchParams?.section === _section.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  searchParams?.section === _section.href ? "page" : undefined
                }
              >
                {_section.name}
              </Link>
            ))}
            <div className='h-6 w-px bg-gray-300' />
            {altSections.map((_section) => (
              <Link
                key={_section.name}
                search={(old) => ({
                  ...old,
                  section: _section.href,
                })}
                className={classNames(
                  searchParams?.section === _section.href
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={
                  searchParams?.section === _section.href ? "page" : undefined
                }
              >
                {_section.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"meter shipments"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            onSearchClicked={setSearch}
            disableSearch={!searchBy}
            renderSearchOption={() => (
              <SelectInput
                id={"searchBy"}
                label={""}
                values={{ searchBy }}
                handleChange={(e: any) => updateSearchBy(e.target.value)}
                handleBlur={undefined}
                options={[
                  {
                    label: "Search By",
                    value: "",
                  },
                  ...searchOptions,
                ]}
              />
            )} // renderExport={({ exportOpen, setExportOpen }) => (
            //   <ExportDataContainer
            //     open={exportOpen}
            //     setOpen={setExportOpen}
            //     entityType="MeterShipment"
            //     filter={filter}
            //   />
            // )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Date
                </th>
                {["InternalShipment", "ExternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Brand
                    </th>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Model
                    </th>
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Serial Numbers
                    </th>
                  </>
                )}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Quantity
                </th>
                {["InternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Status
                  </th>
                )}
                {!["InternalShipment", "ExternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    District
                  </th>
                )}
                {!["InternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Contractor
                  </th>
                )}
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Created By
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                {["InternalShipment", "ExternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.SingleShimmer />
                    </td>
                  </>
                )}
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.SingleShimmer />
                </td>
                {["InternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                {!["InternalShipment", "ExternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                {!["InternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.AvatarShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200'>
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(
                  dispatchAction(
                    item._id,
                    ["InternalShipment", "ExternalShipment"].includes(
                      searchParams?.section as string
                    )
                      ? "view"
                      : "view-order"
                  )
                )}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {moment(item?.createdAt).format(dateFormat + ", hh:mm A")}
                  </div>
                </td>
                {["InternalShipment", "ExternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                      <div className='text-gray-500 dark:text-gray-500'>
                        {item?.model?.brand?.name || "N/A"}
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                      <div className='text-gray-500 dark:text-gray-500'>
                        {item?.model?.name || "N/A"}
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                      <div className='text-gray-500 dark:text-gray-500'>
                        {item?.startSerialNumber} - {item?.endSerialNumber}
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                      <div className='text-gray-500 dark:text-gray-500'>
                        {numeral(item?.quantity).format("0,0") || "N/A"}
                      </div>
                    </td>
                  </>
                )}
                {!["InternalShipment", "ExternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                    <div className='text-gray-500 dark:text-gray-500'>
                      {numeral(item?.meta?.totalSuccessAssignments).format(
                        "0,0"
                      ) || "N/A"}
                    </div>
                  </td>
                )}
                {["InternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <span
                      className={classNames(
                        item?.status === "Pending"
                          ? `bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-50`
                          : "",
                        item?.status === "InProgress"
                          ? `bg-yellow-100 text-yellow-800 dark:bg-yellow-600 dark:text-gray-50`
                          : "",
                        item?.status === "Completed"
                          ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                          : "",
                        !item?.status ? "bg-gray-100 text-gray-800" : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>{item?.status || "Unknown"}</span>
                    </span>
                  </td>
                )}

                {!["InternalShipment", "ExternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-left'>
                    <div className='text-gray-500 dark:text-gray-500'>
                      {item?.district?.name || "N/A"}
                    </div>
                  </td>
                )}
                {!["InternalShipment"].includes(
                  searchParams?.section as string
                ) && (
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-left'>
                    <div className='text-gray-500 dark:text-gray-500'>
                      {item?.contractor?.name || "N/A"}
                    </div>
                  </td>
                )}
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.createdBy?.lastName || "")?.trim(),
                            (item?.createdBy?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.createdBy?.profileImageUrl || ""}
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {[
                          (item?.createdBy?.lastName || "")?.trim(),
                          (item?.createdBy?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.createdBy?.code || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(
                        item?._id,
                        ["InternalShipment", "ExternalShipment"].includes(
                          searchParams?.section as string
                        )
                          ? "view"
                          : "view-order"
                      )}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      <CreateMeterShipmentContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={refetch}
      />
      {!!searchParams.id?.length && !!searchParams.modal?.length && (
        <>
          {["InternalShipment", "ExternalShipment"].includes(
            searchParams?.section as string
          ) && (
            <ViewMeterShipmentContainer
              open={modal === "view"}
              setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
            />
          )}
          {!["InternalShipment", "ExternalShipment"].includes(
            searchParams?.section as string
          ) && (
            <ViewOrderMeterShipmentContainer
              open={modal === "view-order"}
              setOpen={(val: boolean) =>
                setModal(val ? "view-order" : undefined)
              }
            />
          )}
        </>
      )}
    </main>
  );
};

export default MeterShipmentsPage;
