import { SelectInput, TextInput } from "components/core";
import { MeterBrandPicker } from "containers";
import { FormikProps } from "formik";
import { FC } from "react";

interface MeterModelFormProps {
  form: FormikProps<{
    code: string;
    name: string;
    brand: any;
    voltage: string;
    currentRating: string;
    mechanism: string;
    communicationType: string;
    digits: number;
    phase: string;
    type: "Prepaid" | "Postpaid" | "AMR";
  }>;
}

const MeterModelForm: FC<MeterModelFormProps> = ({ form }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Meter Model Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="">
            <MeterBrandPicker
              id="brand"
              label="Brand"
              placeholder="e.g. Huawei"
              {...form}
              required={true}
              rawId={true}
            />
          </div>

          <div className="">
            <TextInput
              id="code"
              label="Model Code"
              type="text"
              placeholder="e.g. ML000"
              {...form}
              required={true}
            />
          </div>

          <div className="">
            <TextInput
              id="name"
              label="Model Name"
              type="text"
              placeholder="e.g. HGE034"
              {...form}
              required={true}
            />
          </div>
        </div>
      </div>

      <div className="pt-6">
        <span className="text-xs font-light">
          Meter Model Specification Information
        </span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <SelectInput
              id="type"
              label="Meter Type"
              placeholder="e.g. Prepaid"
              {...form}
              required={true}
              options={[
                { label: "--- select meter type ---", value: "" },
                { label: "AMR", value: "AMR" },
                { label: "Prepaid", value: "Prepaid" },
                { label: "Postpaid", value: "Postpaid" },
              ]}
            />
          </div>

          <div>
            <SelectInput
              id="communicationType"
              label="Communication Type"
              placeholder="e.g. Prepaid"
              {...form}
              required={true}
              options={[
                { label: "--- select meter communication ---", value: "" },
                { label: "No Communication", value: "NoWay" },
                { label: "One Way Communication", value: "OneWay" },
                { label: "Two Way Communication", value: "TwoWay" },
              ]}
            />
          </div>
          <div>
            <SelectInput
              id="phase"
              label="Meter Phase"
              placeholder="e.g. 1 Phase"
              {...form}
              required={true}
              options={[
                { label: "--- select meter phase ---", value: "" },
                { label: "1 Phase", value: '1 phase' },
                { label: "3 Phase", value: '3 phase' },
                { label: "3 Phase CT", value: '3 phase CT' },
                { label: "3 Phase / 4 Wire", value: '3 phase/4wire' },
              ]}
            />
          </div>
          <div>
            <SelectInput
              id="mechanism"
              label="Meter Mechanism"
              placeholder="e.g. 1 Phase"
              {...form}
              required={true}
              options={[
                { label: "--- select mechanism ---", value: "" },
                { label: "Electronic", value: "Electronic" },
                { label: "ElectroMechanical", value: "ElectroMechanical" },
                { label: "Mechanical", value: "Mechanical" },
              ]}
            />
          </div>
          <div>
            <SelectInput
              id="digits"
              label="Meter Digits"
              placeholder="e.g. Prepaid"
              {...form}
              required={true}
              options={[
                { label: "--- select meter digits ---", value: "" },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
                { label: "6", value: 6 },
                { label: "7", value: 7 },
                { label: "8", value: 8 },
                { label: "9", value: 9 },
                { label: "10", value: 10 },
                { label: "11", value: 11 },
                { label: "12", value: 12 },
              ]}
            />
          </div>
          <div>
            <SelectInput
              id="voltage"
              label="Voltage"
              placeholder="e.g. 1 Phase"
              {...form}
              required={true}
              options={[
                { label: "--- select meter phase ---", value: "" },
                { label: "240V", value: "240" },
                { label: "400V", value: "400" },
              ]}
            />
          </div>
          <div className="">
            <TextInput
              id="currentRating"
              label="Current Rating"
              type="text"
              placeholder="e.g. Wasion"
              {...form}
              required={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterModelForm;
