import { FC } from "react";

interface MeterBrandViewProps {
  meterBrand: {
    code: string;
    name: string;
    contractor: {
      code: string;
      name: string;
    };
    system: {
      code: string;
      name: string;
    };
  };
}

const MeterBrandView: FC<MeterBrandViewProps> = ({ meterBrand }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Meter Brand Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterBrand?.code || "N/A"}
            </div>
          </div>

          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">Name</span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterBrand?.name || "N/A"}
            </div>
          </div>

          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              System
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterBrand?.system?.code || "N/A"} (
              {meterBrand?.system?.name || "N/A"})
            </div>
          </div>

          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Contractor
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {meterBrand?.contractor?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterBrandView;
