import { FC, useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, MeterSystemForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { meterSystemValidationSchema } from 'components/forms/meter-system/schema';

const GET_METER_SYSTEM = gql`
  query GetMeterSystem($id: ID!) {
    meterSystem: getMeterSystem(id: $id) {
      _id
      code
      name
      createdAt
      updatedAt
    }
  }
`

const UPDATE_METER_SYSTEM = gql`
  mutation UpdateMeterSystem(
    $id: ID!
    $code: String!
    $name: String!
  ) {
    updateMeterSystem(
      id: $id
      code: $code
      name: $name
    ) {
      _id
    }
  }
`

interface IUpdateMeterSystemContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const UpdateMeterSystemContainer: FC<IUpdateMeterSystemContainerProps> = ({ open, setOpen, refetch }) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_METER_SYSTEM, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const [updateMeterSystem, { loading }] = useMutation(UPDATE_METER_SYSTEM)

  const form = useFormik({
    initialValues: {
      code: "",
      name: "",
    },
    validationSchema: meterSystemValidationSchema,
    onSubmit: async (values) => {
      await updateMeterSystem({
        variables: {
          id: searchParams.id,
          ...values
        }
      }).then(({ data }) => {
        if (data.updateMeterSystem._id) {
          toast(JSON.stringify({ type: "success", title: "Meter System Edited Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not create Meter System" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      code: data?.meterSystem?.code || "",
      name: data?.meterSystem?.name || "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.meterSystem, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Edit Meter System Information"
      description="Provide the details to update meter system"
      size='2xl'
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Editing Meter System..." : "Edit Meter System"}
          </button>

        </>
      )}>
      {data?.meterSystem?._id && (
        <MeterSystemForm
          form={form}
        />
      )}
    </Modal>
  )
}

export default UpdateMeterSystemContainer;